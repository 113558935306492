import { Box, IconButton, Snackbar } from "@mui/material";
import { Layout, NavigationButtons } from "components";
import { endpoints } from "config";
import {
  usePaymentStatus,
  useRedirectOnOrderStatusChange,
  useRequest,
} from "hooks";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, shoppingCartActions } from "state";
import { images } from "./constant";

const PaymentPix = () => {
  const navigate = useNavigate();
  const handleCopy = () => {
    // @ts-ignore
    // navigator.clipboard.writeText("dalefumico@gmail.com");
    navigator.clipboard.writeText(pixQRCode);
    controllSnackBar();
  };

  const { post } = useRequest();

  const user = useSelector((state: RootState) => state.user);
  const activeOrder = useSelector((state: RootState) => state.activeOrder);
  const [snackBar, setSnackBar] = useState(false);
  const [messageError, setMessageError] = useState("");

  const dispatch = useDispatch();

  const controllSnackBar = () => {
    setSnackBar(!snackBar);
  };

  const requestPix = async () => {
    if (!user.cpf) {
      console.log(
        "usuario sem cpf... alterar no banco de dados ou refazer o cadastro"
      );
    }
    setMessageError("");

    if (activeOrder?.order === undefined) {
      navigate("/order-confirmation");
      return;
    }

    console.log("gerando novo pagamento pix...");

    const payload = {
      payment_method: "PIX",
      order_id: activeOrder.order.id,
    };

    console.log("payment data", { payload });

    const response = await post(endpoints.chargePayment, payload);

    console.log("response", response);

    if (response.error) {
      let message = "erro ao solicitar pagamento via pix.\n";
      if (response.statusCode === 400 && response.message) {
        message += response.message.replace("falha em criar pagamento:", "");
      } else if (response.statusCode === 429) {
        message +=
          "muitas tentativas de pagamento, tente novamente mais tarde.";
      }
      setMessageError(message);
    } else {
      console.log("successfully requested payment");

      const pixQRCode = response.data.qrCode;
      const paymentId = response.data.id;
      const paymentStatus = response.status;
      const pixExpirationDate = response.data.expireAt;

      const pixData = {
        id: paymentId,
        status: paymentStatus,
        pixQRCode,
        pixExpirationDate,
      };

      dispatch(shoppingCartActions.setPixData(pixData));
    }
  };

  const { pixQRCode, pixExpirationDate } = useSelector(
    (state: RootState) => state.shoppingCart.pixData
  );

  useEffect(() => {
    const currentDate = new Date().toISOString();
    const expiredPix = pixExpirationDate && currentDate >= pixExpirationDate;

    if (!pixQRCode || expiredPix) {
      if (expiredPix) {
        console.log("pix vencido, gerando novo...", {
          pixExpirationDate,
          currentDate,
        });
      }

      requestPix();
    }

    // eslint-disable-next-line
  }, []);

  usePaymentStatus();

  useRedirectOnOrderStatusChange();

  return (
    <>
      <Layout>
        <Box paddingBottom="45px">
          <Box
            sx={{
              marginBottom: "47px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <IconButton
                sx={{
                  padding: "0",
                  alignSelf: "start",
                  marginRight: "10px",
                }}
              >
                <img src={images.pix} alt="pix" />
              </IconButton>
              <Box
                sx={{
                  fontWeight: "bold",
                }}
              >
                pix
              </Box>
            </Box>
            <Box
              sx={{
                fontWeight: "light",
                fontSize: "12px",
              }}
            >
              copie o código abaixo para pagar via Pix em qualquer aplicativo
              habilitado:
            </Box>
          </Box>
          {pixQRCode ? (
            <>
              <Box
                sx={{
                  fontWeight: "bold",
                  marginBottom: "24px",
                }}
              >
                pedido aguardando pagamento
              </Box>
              <div
                style={{
                  width: "100%",
                  marginBottom: "32px",
                  display: "flex",
                  justifyContent: "center",
                  background: "white",
                }}
              >
                <div style={{ width: "75%" }}>
                  <QRCode value={pixQRCode} />
                </div>
                {/* <img src={pixQRCodeUrl} alt="qrcode" style={{ width: "75%" }} /> */}
              </div>
              <Box
                sx={{
                  border: "1px dashed black",
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "48px",
                  marginBottom: 1,
                }}
                onClick={handleCopy}
              >
                <Box
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  (Código do Pix)
                </Box>
                <IconButton
                  sx={{
                    position: "absolute",
                    right: "5%",
                  }}
                >
                  <img src={images.copy} alt="copiar" />
                </IconButton>
              </Box>
              <Box
                sx={{
                  fontSize: "12px",
                  fontWeight: "light",
                }}
              >
                você tem 5 minutos para fazer o pagamento. Após esse tempo, o
                pedido será cancelado.
              </Box>
            </>
          ) : (
            <p>{messageError || "carregando..."}</p>
          )}
        </Box>
        <Snackbar
          open={snackBar}
          autoHideDuration={3000}
          onClose={controllSnackBar}
          message="Pix copiado com sucesso!"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
      </Layout>
      <NavigationButtons
        leftTitle="voltar"
        leftAction={() => navigate("/payment-type")}
      />
    </>
  );
};

export default PaymentPix;
