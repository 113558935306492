import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  font-size: 40px;
  justify-content: space-between;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionLabel = styled.div`
  font-family: "Courier New", Courier, monospace;
  font-size: 12px;
`;

export const SectionValue = styled.div`
  height: 40px;
  margin-bottom: 8px;
  font-weight: bold;
`;
