import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import closeIcon from "assets/closeIcon.svg";
import { useState } from "react";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { color_theme } from "../../color-theme";
import ErrorMessage from "../ErrorMessage";

const useStyles = makeStyles({
  container: {
    color: color_theme.text.black.main,
    fontWeight: 300,
    marginBottom: "26px",
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 12px 0px",
    },
    "& fieldset": {
      border: 0,
      borderRadius: 0,
      borderBottom: "1px solid black",
    },
  },
});

const TextInput = ({
  control,
  error,
  mask,
  inputProps,
  includeClearButton,
  afterDelay,
  ...props
}: any) => {
  const classes = useStyles();

  // TODO: change type from any to a timeout type
  const [timer, setTimer] = useState<any>(null);

  const onChangeHandler = (
    value: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (
      v: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
  ) => {
    if (!value) {
      onChange(value);
      return;
    }

    if (afterDelay) {
      clearTimeout(timer);

      onChange(value);

      const newTimer = setTimeout(() => {
        afterDelay(value);
      }, 500);

      setTimer(newTimer);
      return;
    }

    onChange(value);
  };

  return control ? (
    <>
      <Controller
        name={props.name}
        control={control}
        render={({ field: { onChange, ...field } }) =>
          mask ? (
            <NumberFormat
              {...mask}
              customInput={TextField}
              margin="normal"
              fullWidth
              id={props.name}
              className={classes.container}
              onValueChange={(v) => {
                onChangeHandler(v.value, onChange);
              }}
              {...props}
              {...field}
            />
          ) : (
            <TextField
              margin="normal"
              fullWidth
              id={props.name}
              className={classes.container}
              onChange={(v) => onChangeHandler(v.target.value, onChange)}
              {...props}
              {...field}
              inputProps={{ ...inputProps, autoCapitalize: "none" }}
            />
          )
        }
      />
      <ErrorMessage text={error?.message} />
    </>
  ) : (
    <>
      <TextField
        margin="normal"
        fullWidth
        id={props.name}
        className={classes.container}
        {...props}
        inputProps={{ ...inputProps, autoCapitalize: "none" }}
        onChange={(v) => onChangeHandler(v, props.onChange)}
      />
      {includeClearButton && props.value && (
        <span
          style={{
            position: "absolute",
            right: "32px",
            bottom: "12px",
          }}
          onClick={() => props.onChange({ target: { value: "" } })}
        >
          <img
            src={closeIcon}
            alt="limpar"
            style={{
              padding: "12px",
              // height: "12px",
              width: "36px",
            }}
          />
        </span>
      )}
    </>
  );
};

export default TextInput;
