import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 32px 32px;
  background-color: #000;
  border-bottom: 2px solid #fff;
  text-align: center;

  img {
    width: 250px;
  }
`;
