export const color_theme = {
  text: {
    black: {
      main: "#000",
      dark: "#000",
      light: "#161616",
      disabled: "#ababab",
      faded: "#979797",
    },
    white: {
      main: "#fff",
      dark: "#fff",
      light: "#fff",
    },
  },
  background: {
    white: {
      main: "#fff",
      dark: "#fff",
      light: "#fff",
    },
    black: {
      main: "#000",
      dark: "#000",
      light: "#161616",
      disabled: "#ababab",
      faded: "#979797",
    },
  },
  success: {
    main: "#0AC499",
  },
  info: {
    main: "#FCCA49",
  },
  error: {
    main: "#f00",
  },
  button: {
    left: {
      background: "#323232",
    },
  },
};
