import { apiErrorMessages, endpoints } from "config";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { authActions, userActions } from "state";
import useRequest from "./useRequest";

type LoginProps = {
  email: string;
};

type CodeVerify = {
  email?: string;
  code: string;
};

type SignupProps = {
  email: string;
  password: string;
  name: string;
  cpf: string;
};

const useAuth = () => {
  const [errorMessage, setErrorMessage] = useState<undefined | string>();

  const dispatch = useDispatch();
  const { post, loading, patch, get } = useRequest();

  const login = async (data: LoginProps) => {
    setErrorMessage(undefined);

    const response = await post(endpoints.login, data);

    if (response.error) {
      let message =
        apiErrorMessages[response?.message] || apiErrorMessages["default"];
      setErrorMessage(message);
    }

    return response;
  };

  const codeVerify = async (data: CodeVerify) => {
    setErrorMessage(undefined);

    const payload = { ...data };
    const response = await post(endpoints.codeVerify, payload);

    if (!response.error) {
      dispatch(userActions.setUser(response.user));
      dispatch(authActions.login(response?.access_token));
    } else {
      let message = response?.message
        ? apiErrorMessages[response?.message] || response?.message
        : apiErrorMessages["default"];
      setErrorMessage(message);
    }

    return response;
  };
  const signup = async (
    data: SignupProps & { accepted_terms_of_use?: boolean }
  ) => {
    setErrorMessage(undefined);

    data.accepted_terms_of_use = true;
    const response = await post(endpoints.signup, data);

    if (response.error) {
      dispatch(authActions.login(response?.access_token));
      let message = response?.message
        ? apiErrorMessages[response?.message] || response?.message
        : apiErrorMessages["default"];
      setErrorMessage(message);
    }

    return response;
  };

  const logout = () => {
    dispatch(authActions.logout());
  };

  const refresh = async () => {
    const user = await get(endpoints.profile);

    if (user.id) dispatch(userActions.setUser(user));
  };

  const acceptedTermsOfUse = async (id: string) => {
    const response = await patch(endpoints.accepted_terms_of_use(id), {
      accepted_terms_of_use: true,
    });
    if (!response.error) {
      dispatch(userActions.confirmTermsOfUse());
    } else {
      let message = response?.message
        ? apiErrorMessages[response?.message] || response?.message
        : apiErrorMessages["default"];
      setErrorMessage(message);
    }

    return response;
  };

  return {
    login,
    signup,
    codeVerify,
    logout,
    loading,
    errorMessage,
    acceptedTermsOfUse,
    refresh,
  };
};

export default useAuth;
