import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";

// import { messagesActions } from "state";
import { apiErrorMessages, endpoints } from "config";

import { activeChatActions, RootState } from "state";
import useRequest from "./useRequest";

const useChat = (inChat = false) => {
  const dispatch = useDispatch();
  const { get, post, loading } = useRequest();
  const userId = useSelector((state: RootState) => state.user.id);
  const activeOrder = useSelector(
    (state: RootState) => state.activeOrder.order
  );
  const orderId = activeOrder?.id;
  const newMessageCount = useSelector(
    (state: RootState) => state.activeChat.new_message_count
  );

  const HOST = useSelector((state: RootState) => state.api.host);

  useEffect(() => {
    if (inChat) {
      dispatch(activeChatActions.setReadMessages());
    }

    if (!orderId) {
      console.log("(websocket) order id nao encontrado...");
      return;
    }

    const socket = io(HOST.concat(endpoints.chatWebsocket), {
      query: { order: orderId },
    });

    socket.on("connect", () => {
      console.log("[chat] connected...");
    });

    socket.on("disconnect", () => {
      console.log("[chat] connected...");
    });

    socket.on("new-message", (payload) => {
      const message = {
        id: payload.id,
        message: payload.message,
        created_at: payload.created_at,
        user: payload.from || payload.user,
      };

      console.log({ payload, message });

      if (inChat) {
        if (payload.from || message.user.id !== userId) {
          dispatch(activeChatActions.appendMessage(message));
        }
      } else {
        dispatch(activeChatActions.setNewMessage());
      }
    });

    return () => {
      socket.close();
    };
    // eslint-disable-next-line
  }, []);

  const listMessages = async () => {
    if (!orderId) return;
    const response = await get(endpoints.messages(orderId));

    if (!response.error) {
      dispatch(activeChatActions.setMessages(response || []));
    } else {
      let message =
        apiErrorMessages[response?.message] || apiErrorMessages["default"];
      console.log("failed request to list messages:", message);
    }

    return response;
  };

  const createMessage = (message: string) => {
    if (!orderId) return;
    post(endpoints.messages(orderId), { message });
  };

  return { listMessages, createMessage, loading, newMessageCount };
};

export default useChat;
