import Grid from "@mui/material/Grid";

import { Container } from "@mui/material";
import { DEFAULT_PRODUCT_IMAGE } from "config";
import { formatCurrency } from "utils";

const ProductItem = (props: ProductType) => {
  const { showPrice, ...product } = props;
  const [name, flavor] = product.name.split(" - ");

  return (
    <>
      <Container
        maxWidth="xs"
        sx={{
          pt: 3,
        }}
      >
        <Grid
          container
          sx={{
            marginBottom: 4,
            flexWrap: "nowrap",
            width: "100%",
            fontWeight: 500,
          }}
        >
          <Grid
            item
            xs={1}
            sx={{
              fontSize: "22px",
              display: "flex",
              alignItems: "center",
              mr: "45px",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "27px",
            }}
          >
            {product.quantity}
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              minWidth: "60px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={product.image_url || DEFAULT_PRODUCT_IMAGE}
              style={{
                width: "87px",
                objectFit: "cover",
                position: "relative",
                left: -30,
              }}
              alt="Imagem do produto"
            />
          </Grid>
          <Grid
            item
            xs={11}
            height="120px"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              paddingLeft: "16px",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "17px",
            }}
          >
            <div>{name}</div>
            <div
              style={{
                marginBottom: "23px",
              }}
            >
              {flavor}
            </div>
            <div
              style={{
                fontWeight: 600,
                fontSize: 12,
                lineHeight: "14.63px",
                fontFamily: "Montserrat",
                display: "flex",
                marginBottom: "6px",
              }}
            >
              {/* <div>{formatCurrency(product.price)}</div> */}
              {product.subtotal_integral !== product.subtotal && (
                <span
                  style={{
                    fontWeight: 400,
                    textDecoration: "line-through",
                    marginRight: "8px",
                  }}
                >
                  {formatCurrency(product.subtotal_integral)}
                </span>
              )}
              <span> {formatCurrency(product.subtotal)}</span>
            </div>
            <div
              style={{
                fontWeight: 300,
                fontSize: 12,
                lineHeight: "14.63px",
                fontFamily: "Montserrat",
              }}
            >
              <div>{name}</div>
              <div>{flavor}</div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ProductItem;
