import { Box, Button, IconButton, Typography } from "@mui/material";
import { ModalDialog } from "components";
import { useOrders } from "hooks";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "state";
import { images } from "./constants";

interface CodeDeliveryProps {
  open: boolean;
  setOpen(value: boolean): void;
}

const CodeDelivery: FC<CodeDeliveryProps> = ({ open, setOpen }) => {
  const order = useSelector((state: RootState) => state.activeOrder.order);
  const { setStatusOrder } = useOrders();
  const handleClose = () => {
    setOpen(false);
  };

  const confirmDelivery = () => {
    setStatusOrder(order?.id, "FINISHED");
    setOpen(false);
  };

  return (
    <ModalDialog open={open} setOpen={setOpen} width="90%">
      <Box
        sx={{
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <IconButton sx={{ padding: 0 }} onClick={handleClose}>
            <img
              src={images.closeIcon}
              style={{
                objectFit: "cover",
                width: "24px",
                marginBottom: "32px",
              }}
              alt="fechar código de entrega"
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Courier Prime",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "40px",
              lineHeight: "45px",
              marginBottom: "64px",
            }}
          >
            {order?.contact_number?.slice(-4)}
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "20px",
              textTransform: "none",
              width: "100%",
              padding: "16px 40px",
            }}
            onClick={confirmDelivery}
          >
            confirmar entrega (clique aqui apenas após receber o pedido)
          </Button>
        </Box>
      </Box>
    </ModalDialog>
  );
};
export default CodeDelivery;
