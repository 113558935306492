import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Layout, NavigationButtons, ScreenTitle } from "components";
import { useOrders } from "hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "routing/AnimatedPage";
import { RootState } from "state";
import { texts } from "./constants";
import OrderItem from "./OrderItem";

const OrdersList = () => {
  const navigate = useNavigate();
  const { getOrders, loading } = useOrders();

  const allOrders = useSelector((state: RootState) => state.orders.orders);
  useEffect(() => {
    getOrders();

    // eslint-disable-next-line
  }, []);

  const next = () => {
    navigate("/");
  };

  const orderFilters = ["PENDINGPAYMENT"];
  const orders = allOrders.filter(
    (order) => !orderFilters.includes(order.status)
  );

  return (
    <>
      <Layout>
        <>
          <AnimatedPage>
            <ScreenTitle>{texts.screenTitle}</ScreenTitle>

            <Box
              width="100%"
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                mt: 4,
              }}
            >
              {loading && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    paddingBottom: "20%",
                  }}
                >
                  <CircularProgress color="primary" />
                </Box>
              )}
              {orders.map((order, index) => (
                <OrderItem
                  {...order}
                  key={order.id}
                  hasDivider={index + 1 < orders.length}
                />
              ))}
            </Box>
          </AnimatedPage>
        </>
      </Layout>
      <NavigationButtons rightTitle={texts.next} rightAction={next} />
    </>
  );
};

export default OrdersList;
