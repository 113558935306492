import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  products: ProductType[];
  categories: CategoriesType[];
  salesLoading: boolean;
  brandsLoading: boolean;
  acessoApp: boolean;
  loading: boolean;
}

const initialState: InitialState = {
  products: [],
  categories: [],
  salesLoading: false,
  brandsLoading: false,
  acessoApp: false,
  loading: true,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      return {
        ...state,
        products: action.payload.products,
        categories: action.payload.categories,
        acessoApp: action.payload.acessoApp || false,
        loading: false,
      };
    },
    setLoading: (state, action) => {
      state.salesLoading = action.payload;
    },
    setLoadingBrands: (state, action) => {
      state.brandsLoading = action.payload;
    },
  },
});

export const { actions, reducer } = productsSlice;
