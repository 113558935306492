import { Box, Typography } from "@mui/material";
import { NavigationButtons } from "components";
import ModalDialog from "components/ModalDialog";
import { useAuth } from "hooks";
import { useSelector } from "react-redux";
import { RootState } from "state";

const TermsOfUse = () => {
  const user = useSelector((state: RootState) => state.user);
  const { acceptedTermsOfUse, loading } = useAuth();
  const confirmTermsOfUse = () => {
    user.id && acceptedTermsOfUse(user.id);
  };

  return (
    <ModalDialog open={!user.accepted_terms_of_use}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 4,
        }}
      >
        <Typography sx={{ textAlign: "left" }}>
          Li e aceito os{" "}
          <a download href="/termos-de-uso-e-politicas.pdf">
            termos de uso e politicas de privacidade
          </a>
        </Typography>
      </Box>
      <NavigationButtons
        removeArrows
        rightTitle="aceitar"
        rightAction={confirmTermsOfUse}
        rightLoading={loading}
      />
    </ModalDialog>
  );
};
export default TermsOfUse;
