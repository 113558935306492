import { GOOGLE_MAPS_API_KEY } from "config";
import Geocode from "react-geocode";

Geocode.setApiKey(GOOGLE_MAPS_API_KEY);

// set response language. Defaults to english.
Geocode.setLanguage("pt-br");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("br");

interface addressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

const useGeocoder = () => {
  const geocode = async (address: string) => {
    return Geocode.fromAddress(address).then(
      (response: any) => {
        return response.results[0].geometry.location;
      },
      (error: any) => {
        console.error(error);
        return { error: true };
      }
    );
  };

  const getAddressFromLatLng = (lat: string, lng: string) => {
    const getCity = (addresses: addressComponent[]) => {
      return addresses.filter((address: addressComponent) =>
        address.types.includes("administrative_area_level_2")
      )[0].long_name;
    };

    const getState = (addresses: addressComponent[]) => {
      return addresses.filter((address: addressComponent) =>
        address.types.includes("administrative_area_level_1")
      )[0].short_name;
    };

    const getStreet = (addresses: addressComponent[]) => {
      return addresses.find((address: addressComponent) =>
        address.types.includes("route")
      )?.short_name;
    };

    const getNumber = (address: addressComponent[]) => {
      return address.find((address: addressComponent) =>
        address.types.includes("street_number")
      )?.short_name;
    };

    const getDistrict = (address: addressComponent[]) => {
      return address.find((address: addressComponent) =>
        address.types.includes("sublocality_level_1")
      )?.long_name;
    };

    return Geocode.fromLatLng(lat, lng).then(
      (response: any) => {
        let zipcode = "";
        try {
          const result = (
            response.results.filter((res: any) =>
              res.types.includes("street_address")
            )[0] || response.results[0]
          ).address_components.filter((component: any) =>
            component.types.includes("postal_code")
          );
          zipcode = result[0].short_name;
        } catch (err) {
          console.log("failed to find zipcode:", err);
          // todo: add error logging to sentry
        }

        // console.log("got zipcode:", zipcode);

        const address = response.results[0];

        return {
          zipcode,
          address: address.formatted_address || "",
          street: getStreet(address.address_components) || "",
          number: getNumber(address.address_components) || "",
          district: getDistrict(address.address_components) || "",
          city: getCity(address.address_components) || "",
          state: getState(address.address_components) || "",
        };
      },
      (error: any) => {
        throw new Error("Algo deu errado, tente novamente mais tarde.");
      }
    );
  };
  return { geocode, getAddressFromLatLng };
};

export default useGeocoder;
