import { createSlice } from "@reduxjs/toolkit";
import { addDays } from "date-fns";

export const PROMO_WELCOME_ID = "PROMO_WELCOME";

interface InitialState {
  welcome: {
    id: string;
    start_date: null | Date;
    end_date: null | Date;
    selected?: string;
  };
}

const initialState: InitialState = {
  welcome: {
    id: PROMO_WELCOME_ID,
    start_date: null,
    end_date: null,
    selected: "ignite",
  },
};

const promoSlice = createSlice({
  name: "promo",
  initialState,
  reducers: {
    startWelcomePromo: (state) => {
      if (!state.welcome.end_date) {
        const start_date = new Date();
        state.welcome.start_date = start_date;
        state.welcome.end_date = addDays(start_date, 7);
      }
    },
    restartWelcomePromo: (state) => {
      const start_date = new Date();
      state.welcome.start_date = start_date;
      state.welcome.end_date = addDays(start_date, 7);
    },
    setSelectedSubcategory: (state, action) => {
      state.welcome.selected = action.payload;
    },
  },
});

export const { actions, reducer } = promoSlice;
