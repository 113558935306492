import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";

import { endpoints } from "config";
import { activeOrderActions, RootState } from "state";

const useOrderStatus = () => {
  const dispatch = useDispatch();

  const activeOrder = useSelector(
    (state: RootState) => state.activeOrder.order
  );
  const orderId = activeOrder?.id;

  const orderStatus = activeOrder?.status || "PENDING";

  const HOST = useSelector((state: RootState) => state.api.host);

  const registerWebsocket = () => {
    if (!orderId) {
      console.log("[websocket: order status] order id nao encontrado...");
      return;
    }

    const socket = io(HOST.concat(endpoints.orderStatusWebsocket), {
      query: { order: orderId },
    });

    socket.on("connect", () => {
      console.log("[order-status] connected...");
    });

    socket.on("disconnect", () => {
      console.log("[order-status] disconnected...");
    });

    socket.on("order-status", (payload) => {
      // todo: udpate current order
      console.log("received order status update:", payload);
      dispatch(activeOrderActions.setOrder(payload));
    });

    return () => {
      socket.close();
    };
  };

  useEffect(() => {
    return registerWebsocket();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return registerWebsocket();
    // eslint-disable-next-line
  }, [orderId]);

  return { orderStatus };
};

export default useOrderStatus;
