import moto from "assets/moto.svg";
import clock from "assets/clockicon.svg";
import completed from "assets/completed.svg";
import close from "assets/xIcon.svg";

export const images = {
  moto,
  clock,
  completed,
  close,
};
