import confirm from "assets/arrowRight.svg";
import immediateDelivery from "assets/immediateDelivery.png";
import immediateDeliverySelected from "assets/immediateDeliverySelected.png";
import pencil from "assets/pencil.svg";
import scheduleDelivery from "assets/scheduleDelivery.png";
import scheduleDeliverySelected from "assets/scheduleDeliverySelected.png";

export const texts = {
  screenTitle: "checkout",
  infoDeliveryTitle: "informações para entrega",
  recipient: "quem vai receber",
  address: "endereço",
  addCupom: "adicionar cupom",
  observation: "alguma observação?",
  observationPlaceholder: "cor da roupa, cabelo, local...",
  chipOutlined: "entrega em até 12h",
  delivery: "escolha quando será entregue: ",
  payment: "pagamento via boleto",
  paymentValidate: "*Boleto valido por 14 dias",
  purchasingPolicy: "política de compras",
  next: "confirmar",
  cancel: "cancelar",
};

export const images = {
  immediateDelivery,
  immediateDeliverySelected,
  scheduleDelivery,
  scheduleDeliverySelected,
  pencil,
  confirm,
};
