import { GOOGLE_MAPS_API_KEY } from "config";
import marker from "assets/marker.svg";

export const texts = {
  googleMapsUrl: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
};

export const images = {
  marker,
};
