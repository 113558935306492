import chatMessage from "assets/chatMessage.svg";
import marker from "assets/marker.svg";
import { color_theme } from "../../color-theme";

export const texts = {
  waiting: "aguardando",
};

export const images = {
  chatMessage,
};

export const style = {
  containerStyle: {
    width: "100%",
    height: "100%",
  },
  styleMarker: {
    icon: marker,
  },
  styleRoute: {
    strokeColor: "#669df6",
    strokeOpacity: 1,
    strokeWeight: 10,
    fillColor: color_theme.text.black.main,
    fillOpacity: 1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
  },
};

export const center = {
  lat: -12.934043276346886,
  lng: -38.48329104700273,
};

export const path = [
  { lat: -12.934043276346886, lng: -38.48329104700273 },
  { lat: -12.932711142208621, lng: -38.4819061945638 },
];
