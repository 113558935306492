import { apiErrorMessages, endpoints } from "config";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activeOrderActions,
  ordersActions,
  RootState,
  shoppingCartActions,
} from "state";
import useRequest from "./useRequest";

// type CreateOrderProps = {
//   email: string;
//   password: string;
// };

const useOrder = () => {
  const [errorMessage, setErrorMessage] = useState<undefined | string>();

  const dispatch = useDispatch();
  const { get, post, loading, patch } = useRequest();

  const { user, shoppingCart } = useSelector((state: RootState) => state);
  const observation = useSelector(
    (state: RootState) => state.shoppingCart.observation
  );
  const coupon = useSelector((state: RootState) => state.shoppingCart.coupon);

  const {
    addressStreet,
    addressCityState,
    coordinates,
    addressNumber,
    addressComplement,
    zipcode,
    street,
    district,
    city,
    state: stateShortName,
  } = useSelector((state: RootState) => state.geolocation.selectedAddress);
  const getOrders = async () => {
    setErrorMessage(undefined);

    const response = await get(endpoints.orders);

    if (!response.error) {
      let data = response.orders.sort((a: any, b: any) =>
        a.id > b.id ? -1 : 1
      );

      if (data?.length) {
        dispatch(ordersActions.setOrders(data));
      }
    } else {
      let message =
        apiErrorMessages[response?.message] || apiErrorMessages["default"];
      setErrorMessage(message);
    }

    return response;
  };

  const createOrder = async () => {
    setErrorMessage(undefined);

    let delivery_address = addressStreet;

    if (addressCityState) {
      delivery_address += ` - ${addressCityState}, Brasil`;
    } else {
      delivery_address += `, Brasil`;
    }

    const data = {
      client: { id: user.id },
      products: shoppingCart.products.map((prod) => ({
        id: prod.id,
        quantity: parseInt(prod.quantity),
      })),
      delivery_address,
      delivery_address_zipcode: zipcode,
      delivery_address_number: addressNumber,
      delivery_address_complement: addressComplement,
      delivery_street: street,
      delivery_district: district,
      delivery_city: city,
      delivery_state: stateShortName,
      delivery_coords: {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      },
      client_name: user.name,
      contact_number: user.phone,
      recipient_name: shoppingCart.recipientName,
      recipient_description: shoppingCart.recipientDescription,
      observation: observation,
      coupon_code: coupon?.toUpperCase(),
    };

    const response = await post(endpoints.createOrder, data);

    if (!response.error) {
      dispatch(ordersActions.addOrder(response));
      dispatch(activeOrderActions.setOrder(response));
      dispatch(shoppingCartActions.setPixData({}));
    } else {
      let message =
        apiErrorMessages[response?.message] ||
        response.message ||
        apiErrorMessages["default"];
      setErrorMessage(message);
    }

    return response;
  };

  const setStatusOrder = async (id: any, status: string) => {
    setErrorMessage(undefined);

    const data = {
      status,
    };

    const response = await patch(`${endpoints.orders}/${id}/status`, data);

    if (!response.error) {
      dispatch(activeOrderActions.setStatus(status));
    } else {
      let message =
        apiErrorMessages[response?.message] ||
        response.message ||
        apiErrorMessages["default"];
      setErrorMessage(message);
    }

    return response;
  };

  return { getOrders, createOrder, loading, errorMessage, setStatusOrder };
};

export default useOrder;
