import { FC, useState } from "react";

import { IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import { formatCurrency, formatDate, formatHour } from "utils";

import { ChipOutlined, ScreenTitle, StepperStatus } from "components";

import { color_theme } from "../../color-theme";
import { images, texts } from "./constants";

type Props = OrderType & { hasDivider?: boolean };

type StringObjectMap = {
  [a: string]: string;
};
const status_text_map: StringObjectMap = {
  PENDINGPAYMENT: "aguardando pagamento",
  PENDING: "confirmando seu pedido",
  APPROVED: "pedido aceito",
  QUEUED: "preparando seu pedido",
  DELIVERING: "seu pedido saiu para entrega",
  FINISHED: "pedido entregue",
  CANCELLED: "pedido cancelado",
  REJECTED: "pedido cancelado",
  default: "em andamento",
};

const status_color_map: StringObjectMap = {
  PENDINGPAYMENT: "info",
  PENDING: "warning",
  APPROVED: "warning",
  QUEUED: "warning",
  DELIVERING: "warning",
  FINISHED: "success",
  CANCELLED: "error",
  REJECTED: "error",
  default: "warning",
};

const OrderItem: FC<Props> = ({ hasDivider, ...order }) => {
  const statusText =
    status_text_map[order.status] || status_text_map["default"];
  const statusColor =
    status_color_map[order.status] || status_color_map["default"];

  const [expanded, setExpanded] = useState(false);
  const detailsOrder = () => setExpanded(!expanded);

  const apiStatusMap = {
    PENDINGPAYMENT: 0,
    PENDING: 0,
    APPROVED: 1,
    QUEUED: 1,
    DELIVERING: 2,
    FINISHED: 3,
    CANCELLED: 3,
    REJECTED: 3,
  };

  const activeStep =
    apiStatusMap[order.status as keyof typeof apiStatusMap] || 0;

  const total = order.products
    .map((product) => product.quantity)
    .reduce((a, b) => a + b, 0);

  const steps = [
    "confirmando seu pedido",
    "preparando seu pedido",
    "seu pedido saiu para entrega",
    order.status === "FINISHED" ? "pedido entregue" : "pedido cancelado",
  ];

  return (
    <Box sx={{ marginBottom: 4, fontWeight: 500 }} onClick={detailsOrder}>
      <Grid container sx={{ paddingBottom: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              width: "100%",
              mb: expanded ? 5 : 0,
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mb: 2,
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20px",
                }}
              >
                <Box sx={{ mr: 1 }}>{formatDate(order.created_at)}</Box>
                {!expanded ? (
                  <Box>
                    <ChipOutlined label={statusText} color={statusColor} />
                  </Box>
                ) : (
                  <Box>às {formatHour(order.created_at)}</Box>
                )}
              </Box>

              {/* valor total */}
              {!expanded ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <Grid sx={{ mr: 2 }}>{`${total} unidades`}</Grid>
                  <Grid>
                    subtotal: {formatCurrency(parseInt(order.fullprice))}
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Box>

            {/* icone abrir e fechar */}
            <Box
              sx={{
                display: "flex",
                aligntItems: "center",
              }}
            >
              {expanded ? (
                <IconButton>
                  <img src={images.arrowUp} alt="esconder produtos" />
                </IconButton>
              ) : (
                <IconButton>
                  <img src={images.arrowDown} alt="visualizar produtos" />
                </IconButton>
              )}
            </Box>
          </Box>

          {expanded ? (
            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <StepperStatus
                activeStep={activeStep}
                steps={steps}
                orderStatus={order.status}
              />
              <Box sx={{ mt: 5 }}>
                <Box height="100%" width="100%">
                  <Box sx={{ borderLeft: 4, mb: 5 }}>
                    {order.products.map((product) => (
                      <Grid
                        container
                        sx={{
                          pl: "12px",
                          mb: "15px",
                        }}
                        key={product.id}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{
                            fontWeight: 500,
                            mb: "6px",
                          }}
                        >
                          <Grid item xs={12}>
                            {product.name.split(" - ")[0]}
                          </Grid>
                          <Grid item xs={12}>
                            {product.name.split(" - ")[1]}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            fontWeight: 300,
                            fontSize: 16,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Grid mr="4px">{product.quantity} unidades</Grid>
                          <Grid>{formatCurrency(product.price)}</Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      fontWeight: 300,
                      fontSize: 16,
                      display: "flex",
                      flexDirection: "row",
                      mb: 5,
                    }}
                  >
                    <Box mr="8px" fontWeight="600">
                      subtotal:
                    </Box>
                    <Box>{formatCurrency(parseInt(order.fullprice))}</Box>
                  </Box>
                  <Box>
                    <ScreenTitle>{texts.infoDeliveryTitle}</ScreenTitle>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        mt: 5,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "14px",
                          lineHeight: "17px",
                          mt: 2,
                        }}
                      >
                        {texts.address}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 300,
                          fontSize: "16px",
                          mt: 1,
                          lineHeight: "20px",
                        }}
                      >
                        {order.delivery_address}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Grid>
      {hasDivider && (
        <Divider
          sx={{
            borderColor: color_theme.background.black.main,
            marginBottom: 2,
            position: "relative",
            left: -32,
            width: "100vw",
          }}
        />
      )}
    </Box>
  );
};

export default OrderItem;
