import { Box, Container, Typography } from "@mui/material";
import { FC } from "react";
import { color_theme } from "../../color-theme";

interface categoryProps {
  categorySelected: string;
  setCategorySelected: any;
  setSelected: any;
  categories: any;
  hideSelectAll?: boolean;
}

const Category: FC<categoryProps> = ({
  categorySelected,
  setCategorySelected,
  setSelected,
  categories,
  hideSelectAll,
}) => {
  // const categories = useSelector(
  //   (state: RootState) => state.products.categories
  // );
  return (
    <Container
      component="main"
      sx={{
        width: "100vw",
        p: 0,
        m: 0,
        mb: 3,
        paddingRight: "44px",
        overflowX: "scroll",
        "::-webkit-scrollbar": {
          display: "none",
        },
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          margin: 0,
          display: "flex",
          flexDirection: "row",
          width: "auto",
        }}
      >
        {!hideSelectAll && (
          <Box
            sx={{
              display: "flex",
              height: "25.52px",
              border: "2px solid #000",
              borderRadius: "20px",
              ml: "30px",
              mr: "-14px",
              backgroundColor:
                "all" === categorySelected
                  ? color_theme.background.black.main
                  : color_theme.background.white.main,
              color:
                "all" === categorySelected
                  ? color_theme.background.white.main
                  : color_theme.background.black.main,
            }}
            onClick={() => {
              setCategorySelected("all");
              setSelected("all");
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                fontWeight: "700",
                textTransform: "lowerCase",
                padding: "0 16px",
                minWidth: "72px",
              }}
            >
              all
            </Typography>
          </Box>
        )}
        {categories.map((categories: any) => {
          return (
            <Box
              key={categories.name}
              sx={{
                display: "flex",
                flex: 1,
                height: "25.52px",
                border: "2px solid #000",
                borderRadius: "20px",
                ml: "30px",
                mr: "-14px",
                backgroundColor:
                  categories.name === categorySelected
                    ? color_theme.background.black.main
                    : color_theme.background.white.main,
                color:
                  categories.name === categorySelected
                    ? color_theme.background.white.main
                    : color_theme.background.black.main,
              }}
              onClick={() => {
                setCategorySelected(categories.name);
                setSelected("all");
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  fontWeight: "700",
                  textTransform: "lowerCase",
                  padding: "0 16px",
                  whiteSpace: "nowrap",
                  minWidth: "72px",
                }}
              >
                {categories.name}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Container>
  );
};

export default Category;
