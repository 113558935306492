import badSmile from "assets/badSmile.png";
import battery from "assets/battery.svg";
import {
  default as closeIcon,
  default as closeIcone,
} from "assets/closeIcon.svg";
import imageDefault from "assets/imageDefault.png";
import bannerPromo from "assets/bannerPromo.png";
import magnifyingGlass from "assets/magnifyingGlass.svg";
import nicotine from "assets/nicotine.svg";
import icon from "assets/RELX_POD.svg";
import selectedIcon from "assets/RELX_POD_SELECIONADO.svg";
import volume from "assets/volume.svg";

export const texts = {
  screenTitlePromo: "promoções",
  screenTitleCatalog: "catálogo",
  login: "entrar",
  next: "avançar",
  suggestedPrice: "preco de revenda sugerido",
  loginToViewPrices: "entre para ver os preços",
  conventionalPrice: "preço convencional",
  promotionalPrice: "preço promocional",
  orderActive: "continue o seu pedido",
  back: "voltar",
  add: "adicionar",
};

export const images = {
  imageDefault,
  closeIcon,
  volume,
  nicotine,
  battery,
  magnifyingGlass,
  closeIcone,
  badSmile,
  bannerPromo,
};

const all = [
  { name: "pod", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "device", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "the black sheep", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "ignite", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "waka", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "concept", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "resistência", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "nasty", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "BLVK", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "sedas", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "filtros", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "trituradores", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "isqueiros", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "tabaco", imgUrl: icon, imgUrlSelected: selectedIcon },
];
const promoções = [
  { name: "promo week", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "combos", imgUrl: icon, imgUrlSelected: selectedIcon },
];
const relx = [
  { name: "pod", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "device", imgUrl: icon, imgUrlSelected: selectedIcon },
];
const descartaveis = [
  { name: "the black sheep", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "ignite", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "waka", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "concept", imgUrl: icon, imgUrlSelected: selectedIcon },
];
const regarregáveis = [
  { name: "resistência", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "nasty", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "BLVK", imgUrl: icon, imgUrlSelected: selectedIcon },
];
const headshop = [
  { name: "sedas", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "filtros", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "trituradores", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "isqueiros", imgUrl: icon, imgUrlSelected: selectedIcon },
  { name: "tabaco", imgUrl: icon, imgUrlSelected: selectedIcon },
];

export const compareCategories = [
  { name: "all", subCategories: all },
  { name: "descartáveis", subCategories: descartaveis },
  { name: "relx", subCategories: relx },
  { name: "promoções", subCategories: promoções },
  { name: "regarregáveis", subCategories: regarregáveis },
  { name: "headshop", subCategories: headshop },
];
