import pen from "assets/pen.svg";
import cpf from "assets/cpf.svg";
import perfil from "assets/perfil.svg";
import brasil from "assets/brazil.svg";
import { FUMICO_PHONE_NUMBER } from "config";

export const texts = {
  back: "voltar",
  cancel: "cancelar",
  next: "salvar",
  email: "seu e-mail",
  phone: "seu telefone",
  newPhone: "seu novo telefone*",
  language: "seu idioma",
  copy_url: "copiar url",
  send: "enviar",
  numberPlaceholder: "(00) 00000-0000",
  invite: {
    title: "quer trazer seu amigo pro fumico?",
    body: [
      "o fumico está mais vip do que nunca! o acesso ao app agora é exclusivo por indicação!",
      "você como um cliente especial já pode indicar seus amigos, acesse o menu no cantinho superior e clique em indique um amigo para permitir que outros ingressem no clube fumico 😎",
    ],
    footer: "qual amigo deseja indicar?",
    success: "*sms enviado com sucesso",
    copied: "*link copiado com sucesso",
    error: [
      "*falha no envio de sms, clique ",
      "aqui",
      " para falar com o suporte",
    ],
    whatsappUrl: `https://api.whatsapp.com/send?phone=${FUMICO_PHONE_NUMBER}&text=`,
    whatsappMessage: "olá! preciso de ajuda para convidar um amigo!",
  },
};

export const images = {
  pen,
  perfil,
  cpf,
  brasil,
};
