import { StepIcon } from "@mui/material";
import { FC } from "react";
import { images } from "./constants";
import { StepBall } from "./style";

interface StepProps {
  stepProps: any;
  orderStatus: string;
}
const CustomStepIcon: FC<StepProps> = ({ stepProps, orderStatus }) => {
  const icons = [
    images.clock,
    images.clock,
    images.moto,
    orderStatus !== "REJECTED" && orderStatus !== "CANCELLED"
      ? images.completed
      : images.close,
  ];

  const realIcon = () => {
    if (stepProps.active) {
      return (
        <StepBall
          style={{
            backgroundColor:
              orderStatus !== "REJECTED" && orderStatus !== "CANCELLED"
                ? "#0AC499"
                : "#FA3D3A",
          }}
        >
          <img src={icons[stepProps.icon - 1]} alt={stepProps.icon} />
        </StepBall>
      );
    }

    if (stepProps.completed) {
      return (
        <StepBall
          style={{
            backgroundColor:
              orderStatus !== "REJECTED" && orderStatus !== "CANCELLED"
                ? "#0AC499"
                : "#FA3D3A",
          }}
        >
          <img src={icons[3]} alt={stepProps.icon} />
        </StepBall>
      );
    }

    return "";
  };

  return <StepIcon icon={realIcon()} />;
};

export default CustomStepIcon;
