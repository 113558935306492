import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { Layout, NavigationButtons } from "components";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { texts } from "./constants";
import * as yup from "yup";
import { errorMessages } from "config";
import { useState } from "react";
import { useRequest, useInvite } from "hooks";
import LoadingOverlay from "screens/OrderConfirmation/LoadingOverlay";
import { color_theme } from "color-theme";
import { useSelector } from "react-redux";
import { RootState } from "state";

const schema = yup
  .object({
    phone: yup
      .string()
      .trim()
      .required(errorMessages.phoneRequired)
      .min(11, errorMessages.phoneMinLength)
      .max(14, errorMessages.phoneMaxLength)
      .nullable(),
  })
  .required();

const UserInvite = () => {
  const [fumicoLoading, setFumicoLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<"success" | "error" | "">("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const apiState = useSelector((state: RootState) => state.api.state);

  const navigate = useNavigate();
  const { loading, setLoading } = useRequest();
  const { createInvite } = useInvite();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: "",
    },
  });

  const onSubmit = async (data: any) => {
    const payload = {
      phone: data.phone,
      state: apiState,
      uri: window.location.origin,
    };
    setLoading(true);
    setFumicoLoading(true);
    setStatus("");
    setError("");
    try {
      const res = await createInvite(payload);

      if (res.error) {
        setError(res.message);
      } else {
        setStatus("success");
        setUrl(res.url);
        setSuccessMessage(texts.invite.success);
      }
    } catch (err) {
      console.error(err);
      setStatus("error");
    } finally {
      setLoading(false);
      setFumicoLoading(false);
    }
  };

  const handleSac = () => {
    const sacContact = `${texts.invite.whatsappUrl}${encodeURI(
      texts.invite.whatsappMessage
    )}`;

    window.open(sacContact, "_blank");
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setSuccessMessage(texts.invite.copied);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {fumicoLoading && <LoadingOverlay />}
        <Layout>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "20px",
                marginBottom: "27px",
              }}
            >
              {texts.invite.title}
            </Typography>
            {texts.invite.body.map((message) => (
              <Typography
                key={`invite-message-${message}`}
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "300",
                  fontSize: "16px",
                  lineHeight: "20px",
                  marginBottom: "20px",
                }}
              >
                {message}
              </Typography>
            ))}
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "20px",
                marginBottom: "24px",
              }}
            >
              {texts.invite.footer}
            </Typography>

            <PhoneInputWithCountry
              defaultCountry="BR"
              international
              countryCallingCodeEditable
              placeholder={texts.numberPlaceholder}
              name="phone"
              control={control}
              style={{
                borderBottom: "1px solid #000",
                marginBottom: "26px",
              }}
            />
            <Box
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
                marginBottom: "20px",
                minHeight: "32px",
              }}
            >
              <div style={{ color: "#A70000" }}>
                {errors["phone"]?.message || error}
                {status === "error" && (
                  <p onClick={handleSac} style={{ cursor: "pointer" }}>
                    {texts.invite.error[0]}
                    <u>{texts.invite.error[1]}</u>
                    {texts.invite.error[2]}
                  </p>
                )}
              </div>
              {status === "success" && (
                <>
                  <div
                    style={{
                      color: "#00A707",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {successMessage}
                    <button
                      type="button"
                      onClick={handleCopy}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: color_theme.background.black.dark,
                        color: color_theme.text.white.main,
                        padding: "6px 40px",
                        font: "600 12px/16px Montserrat",
                        border: "none",
                      }}
                    >
                      {texts.copy_url}
                    </button>
                  </div>
                </>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                type="submit"
                disabled={status === "success"}
                style={{
                  minHeight: "33px",
                  opacity: status === "success" ? 0.5 : 1,
                  backgroundColor: color_theme.background.black.dark,
                  color: color_theme.text.white.main,
                  padding: "6px 42px",
                  font: "600 16px/20px Montserrat",
                  border: "none",
                }}
              >
                {texts.send}
              </button>
            </Box>
          </Box>
        </Layout>
        <NavigationButtons
          leftTitle={texts.back}
          leftAction={() => navigate("/")}
          leftDisabled={loading}
          style={{ backgroundColor: color_theme.background.black.dark }}
        ></NavigationButtons>
      </form>
    </>
  );
};

export default UserInvite;
