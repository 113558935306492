import arrowRight from "assets/arrowRight.png";
import closeIcon from 'assets/closeIconWhite.svg'

export const images = {
  arrowRight,
  closeIcon,
};

export const texts = {
  placeholder: "endereço e número",
};
