import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";

import { apiErrorMessages, endpoints } from "config";
import { productsActions, RootState } from "state";

import useRequest from "./useRequest";

const useProducts = () => {
  const [errorMessage, setErrorMessage] = useState<undefined | string>();

  const dispatch = useDispatch();
  const { get, loading } = useRequest();

  const selected_promo = useSelector((state: RootState) => state.activePromo);

  const appVersionCode = useSelector(
    (state: RootState) => state.api.appVersionCode
  );

  const HOST = useSelector((state: RootState) => state.api.host);

  const getProducts = async () => {
    setErrorMessage(undefined);

    productsActions.setLoading(true);
    const app_version_code_query = `?appVersionCode=${appVersionCode}`;
    const promo_query = selected_promo.active
      ? `&promo=${selected_promo.promo}`
      : ``;
    const request_url =
      endpoints.products + app_version_code_query + promo_query;

    const response = await get(request_url);

    productsActions.setLoading(false);
    if (!response.error) {
      if (response) {
        dispatch(productsActions.setProducts(response));
      }
    } else {
      let message =
        apiErrorMessages[response?.message] || apiErrorMessages["default"];
      setErrorMessage(message);
    }
    return response;
  };

  const productsSocket = () => {
    const socket = io(HOST.concat(endpoints.productsWebsocket));

    socket.on("connect", () => {
      console.log("[product-update] connected...");
    });

    socket.on("disconnect", () => {
      console.log("[product-update] disconnected...");
    });

    socket.on("product-update", (payload) => {
      console.log("received product update:", payload);

      if (payload) {
        getProducts();
      }
    });

    return () => {
      socket.close();
    };
  };

  useEffect(() => {
    return productsSocket();
    // eslint-disable-next-line
  }, []);

  const storeIsOpen = async () => {
    const res = await get(endpoints.store);
    return res;
  };

  return { getProducts, loading, errorMessage, storeIsOpen };
};

export default useProducts;
