import { combineReducers } from "redux";
import { reducer as activeChat } from "./ducks/active-chat";
import { reducer as activeOrder } from "./ducks/active-order";
import { reducer as activePromo } from "./ducks/active-promo";
import { reducer as addressList } from "./ducks/address-list";
import { reducer as addressesUser } from "./ducks/addresses-user";
import { reducer as api } from "./ducks/api";
import { reducer as auth } from "./ducks/auth";
import { reducer as banners } from "./ducks/banners";
import { reducer as geolocation } from "./ducks/geolocation";
import { reducer as loading } from "./ducks/loading";
import { reducer as map } from "./ducks/map";
import { reducer as menu } from "./ducks/menu";
import { reducer as orders } from "./ducks/orders";
import { reducer as products } from "./ducks/products";
import { reducer as promo } from "./ducks/promo";
import { reducer as shoppingCart } from "./ducks/shopping-cart";
import { reducer as storeConfig } from "./ducks/store-config";
import { reducer as user } from "./ducks/user";

const rootReducer = combineReducers({
  auth,
  geolocation,
  orders,
  loading,
  user,
  products,
  shoppingCart,
  menu,
  addressList,
  map,
  addressesUser,
  activeChat,
  activeOrder,
  promo,
  api,
  activePromo,
  banners,
  storeConfig,
});

export default rootReducer;
