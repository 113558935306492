import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  messages: MessageType[];
  new_message_count: number;
}

const initialState: InitialState = {
  messages: [],
  new_message_count: 0,
};

const activeChatSlice = createSlice({
  name: "activeChat",
  initialState,
  reducers: {
    setReadMessages: (state) => {
      state.new_message_count = 0;
    },
    setNewMessage: (state) => {
      state.new_message_count++;
    },
    setMessages: (state, action) => ({ ...state, messages: action.payload }),
    appendMessage: (state, action) => {
      const text = action.payload;
      state.messages.push(text);
    },
  },
});

export const { actions, reducer } = activeChatSlice;
