import Grid from "@mui/material/Grid";
import TagManager from "react-gtm-module";

import { Box, Typography } from "@mui/material";
import { CounterInput, ModalDialog } from "components";
import { memo, useState } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { useSelector } from "react-redux";
import { formatCurrency } from "utils";
import { color_theme } from "../../color-theme";
import { RootState } from "../../state";
import ModalDetailsProduct from "./ModalDetailsProduct";
import { images } from "./constants";
import "./index.css";

function ProductItem(props: ProductType) {
  const { ...product } = props;
  const [name, flavor] = product.name.split(" - ");
  const [openModalDetails, setOpenModalDetails] = useState(false);

  const isWelcomePromoActive = useSelector(
    (state: RootState) => state.activePromo.active
  );

  const blockAddProduct = isWelcomePromoActive && product?.quantity > 0;

  let disabled = !product.has_stock;
  let color = disabled
    ? color_theme.text.black.disabled
    : color_theme.text.black.main;

  const openModalDetailsItem = () => {
    setOpenModalDetails(true);

    const selectItemArgs = {
      dataLayer: {
        event: "select_item",
        currency: "BRL",
        items: [{ item_id: product.sku }],
      },
    };

    TagManager.dataLayer(selectItemArgs);
  };

  return (
    <>
      <Grid
        container
        sx={{
          flexWrap: "nowrap",
          width: "100%",
          fontWeight: 500,
        }}
      >
        <Grid
          item
          xs={1}
          sx={{
            minWidth: "86px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ProgressiveImage src={product.image_url} placeholder="">
            {(src: string, loading: boolean) => {
              return loading ? (
                <img
                  src={product.placeholder_image_url}
                  style={{
                    height: "120px",
                    maxWidth: "87px",
                    objectFit: "cover",
                  }}
                  alt="produto"
                />
              ) : disabled ? (
                <Box sx={{ position: "relative" }}>
                  <img
                    src={src}
                    className="blur"
                    style={{
                      minHeight: "120px",
                      maxWidth: "87px",
                      objectFit: "contain",
                    }}
                    alt="Imagem do produto em falta"
                  />
                  <img
                    src={images.badSmile}
                    style={{
                      minHeight: "120px",
                      maxWidth: "50px",
                      objectFit: "contain",
                      position: "absolute",
                      left: 19,
                    }}
                    alt="Carinha triste"
                  />
                  <Typography
                    sx={{
                      mt: 2,
                      fontFamily: "Courier Prime",
                      textAlign: "center",
                      color: color_theme.text.black.main,
                    }}
                  >
                    em falta
                  </Typography>
                </Box>
              ) : (
                <Box onClick={openModalDetailsItem}>
                  <img
                    src={src}
                    style={{
                      minHeight: "120px",
                      maxWidth: "87px",
                      objectFit: "cover",
                    }}
                    alt="Imagem do produto"
                  />
                </Box>
              );
            }}
          </ProgressiveImage>
        </Grid>
        <Grid
          item
          xs={11}
          minHeight="120px"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            paddingLeft: "16px",
            fontSize: 18,
            color: color,
          }}
        >
          <div>{name}</div>
          <div
            style={{
              fontSize: 14,
              marginBottom: 24,
              color: color,
            }}
          >
            {flavor}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "14px",
              color: color,
              lineHeight: "15px",
            }}
          >
            {product.price !== product.standard_price &&
              product.standard_price !== null && (
                <p
                  style={{
                    color: color_theme.text.black.disabled,
                    fontWeight: 300,
                    textDecoration: "line-through",
                    marginRight: "8px",
                  }}
                >
                  {formatCurrency(product.standard_price)}
                </p>
              )}

            {formatCurrency(product.price)}
          </div>
        </Grid>
        <Box
          sx={{
            marginTop: " -10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CounterInput
            disableAdd={blockAddProduct}
            disabled={disabled}
            id={product.id}
            quantity={product.quantity}
            clearWhenAdding={isWelcomePromoActive}
          />
        </Box>
      </Grid>
      <ModalDialog open={openModalDetails} setOpen={setOpenModalDetails}>
        <ModalDetailsProduct {...product} setOpen={setOpenModalDetails} />
      </ModalDialog>
    </>
  );
}

const Product = memo(ProductItem);
export default Product;
