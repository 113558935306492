import { FC } from "react";
import {
  AddCreditCard,
  AddressComplement,
  ChooseRecipient,
  ConfirmationSMS,
  Login,
  MapsLocal,
  // OrderDelivery,
  OrderChat,
  OrderConfirmation,
  OrderConfirmationStatus,
  // About,
  OrdersList,
  PaymentPix,
  PaymentType,
  ProductCatalog,
  PromoWelcome,
  PromoNotAvailable,
  Signup,
  UserProfile,
  UserInvite,
} from "screens";
import UpdateUserPhone from "screens/User/UpdateUserPhone";

interface Route {
  path: string;
  Component: FC<any>;
  label?: string;
  icon?: string;
}

const userRoutes: Route[] = [
  {
    path: "/promo/welcome",
    Component: PromoWelcome,
  },
  {
    path: "/promo/not-available",
    Component: PromoNotAvailable,
  },
  {
    path: "/",
    // Component: ProductCatalog,
    Component: MapsLocal,
    label: "inicio",
    icon: "icon_home",
  },
  {
    path: "/catalog",
    Component: ProductCatalog,
    // Component: MapsLocal,
    // label: "catalogo",
  },
  {
    path: "/order-confirmation",
    Component: OrderConfirmation,
  },
  {
    path: "/order-confirmation-status",
    Component: OrderConfirmationStatus,
  },
  {
    path: "/recipient",
    Component: ChooseRecipient,
  },
  {
    path: "/payment-type",
    Component: PaymentType,
  },
  {
    path: "/payment-pix",
    Component: PaymentPix,
  },
  {
    path: "add-credit-card",
    Component: AddCreditCard,
    // label: "add credit card",
  },
  {
    path: "/orders",
    Component: OrdersList,
    label: "histórico",
    icon: "icon_docs",
  },
  {
    path: "/userprofile",
    Component: UserProfile,
    label: "minha conta",
    icon: "icon_user",
  },
  {
    path: "/userinvite",
    Component: UserInvite,
  },
  {
    path: "/user/updateuserphone",
    Component: UpdateUserPhone,
  },
  // {
  //   path: "/order-delivery",
  //   Component: OrderDelivery,
  // },
  {
    path: "/order-chat",
    Component: OrderChat,
  },
  // {
  //   path: "/about",
  //   Component: About,
  //   label: "sobre",
  // },
  {
    path: "/address-complement",
    Component: AddressComplement,
  },
];

const guestRoutes: Route[] = [
  {
    path: "/promo/welcome",
    Component: PromoWelcome,
  },
  {
    path: "/promo/not-available",
    Component: PromoNotAvailable,
  },
  {
    path: "/",
    Component: MapsLocal,
    label: "inicio",
    icon: "icon_home",
  },
  {
    path: "/catalog",
    Component: ProductCatalog,
  },
  {
    path: "/recipient",
    Component: ChooseRecipient,
  },
  {
    path: "/login",
    Component: Login,
    label: "entrar",
    icon: "icon_user",
  },
  {
    path: "/confirmation-sms",
    Component: ConfirmationSMS,
  },
  {
    path: "/order-confirmation",
    Component: OrderConfirmation,
  },
  {
    path: "/order-confirmation-status",
    Component: OrderConfirmationStatus,
  },
  {
    path: "/signup",
    Component: Signup,
  },
  {
    path: "/signup/:code",
    Component: Signup,
  },
  // {
  //   path: "/about",
  //   Component: About,
  //   label: "sobre",
  // },
  {
    path: "/address-complement",
    Component: AddressComplement,
  },
];

// const authRoutes: Route[] = []; // TODO

export const routes = {
  userRoutes,
  guestRoutes,
};
