import * as Sentry from "@sentry/react";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions, RootState } from "state";
import app from "../../package.json";

const APP_VERSION = app.version;

const useRequest = () => {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state: RootState) => state.auth.token);
  const HOST = useSelector((state: RootState) => state.api.host);

  const headers = {
    Authorization: `Bearer ${token}`,
    "APP-VERSION": APP_VERSION,
  };

  const dispatch = useDispatch();

  const get = async (endpoint: string) => {
    setLoading(true);
    let res: any;

    try {
      const response = await axios.get(HOST.concat(endpoint), { headers });
      res = response;
    } catch (err: any) {
      res = err.response;
      Sentry.captureException(err);
      if (res.data.statusCode === 401) {
        dispatch(authActions.logout());
      }
    }

    setLoading(false);
    return res?.data;
  };

  const post = async (endpoint: string, body: any) => {
    setLoading(true);
    let res: any;

    try {
      const response = await axios.post(HOST.concat(endpoint), body, {
        headers,
      });
      res = response;
    } catch (err: any) {
      res = {
        ...err.response,
        data: {
          ...err.response?.data,
          error: err.response?.data?.error || true,
        },
      };
      Sentry.captureException(err);

      if (res.data.statusCode === 401) {
        dispatch(authActions.logout());
      }
    }

    setLoading(false);
    return res?.data;
  };

  const patch = async (endpoint: string, body: any) => {
    setLoading(true);
    let res: any;

    try {
      const response = await axios.patch(HOST.concat(endpoint), body, {
        headers,
      });
      res = response;
    } catch (err: any) {
      res = {
        ...err.response,
        data: {
          ...err.response?.data,
          error: err.response?.data?.error || true,
        },
      };
      Sentry.captureException(err);

      if (res.data.statusCode === 401) {
        dispatch(authActions.logout());
      }
    }

    setLoading(false);
    return res?.data;
  };

  const remove = async (endpoint: string, body: any) => {
    setLoading(true);
    let res;
    try {
      const response = await axios.delete(HOST.concat(endpoint), {
        headers,
        data: body,
      });
      res = response;
    } catch (err: any) {
      res = {
        ...err.response,
        data: {
          ...err.response?.data,
          error: err.response?.data?.error || true,
        },
      };
      Sentry.captureException(err);
      if (res.data.statusCode === 401) {
        dispatch(authActions.logout());
      }
    }

    setLoading(false);
    return res?.data;
  };

  return { loading, get, post, patch, remove, setLoading };
};

export default useRequest;
