import { Box } from "@mui/material";

import { FC } from "react";

import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import { ErrorMessage } from "components";
import "./index.css";
import styled from "styled-components";
import "./styles.css";
interface NumberPhoneDDIProps {
  control: any;
  errors: any;
  placeholder: string;
}

const NumberPhoneDDI: FC<NumberPhoneDDIProps> = ({
  control,
  errors,
  placeholder,
}) => {
  return (
    <Box>
      <CustomPhoneInputWithCountry
        defaultCountry="BR"
        international
        countryCallingCodeEditable
        placeholder={placeholder}
        name="phone"
        control={control}
      />
      <ErrorMessage text={errors?.message} />
    </Box>
  );
};

const CustomPhoneInputWithCountry = styled(PhoneInputWithCountry)`
  margin-top: 26px;
  margin-bottom: 26px;
  border-bottom: 1px solid #000;
`;
export default NumberPhoneDDI;
