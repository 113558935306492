import { FC } from "react";

import { Box, Chip } from "@mui/material";

interface ChipOutlinedProps {
  label: string;
  color: any;
}
const ChipOutlined: FC<ChipOutlinedProps> = ({ label, color }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Chip
        sx={{ height: "16px", fontSize: "12px"}}
        label={label}
        color={color}
        variant="outlined"
      />
    </Box>
  );
};
export default ChipOutlined;
