import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { ErrorMessage } from "components";
import { FC } from "react";
import { Controller } from "react-hook-form";

interface TermsOfUseProps {
  control: any;
  error: any;
}

const TermsOfUse: FC<TermsOfUseProps> = ({ control, error }) => {
  return (
    <>
      <FormControlLabel
        label={undefined}
        control={
          <Controller
            name="accepted_terms_of_use"
            control={control}
            render={({ field }) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
                <Typography>
                  Li e aceito oas{" "}
                  <a href="/termos-de-uso-e-politicas.pdf" download>
                    termos de uso
                  </a>
                </Typography>
              </Box>
            )}
          />
        }
      />
      <ErrorMessage text={error?.message} />
    </>
  );
};
export default TermsOfUse;
