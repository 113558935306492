import { HeaderBase } from "components";
import { images } from "./constants";

const Header = () => {
  return (
    <HeaderBase center>
      <img style={{ width: "170px" }} src={images.logo} alt="fumico"></img>
    </HeaderBase>
  );
};

export default Header;
