import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  isLoaded: boolean;
  nextPath: string | null;
}

const initialState: InitialState = {
  isLoaded: false,
  nextPath: null,
};

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoaded = action.payload;
    },
    setNextPath: (state, action) => {
      state.nextPath = action.payload;
    },
  },
});

export const { actions, reducer } = mapSlice;
