import pencil from "assets/pencil.svg";

export const texts = {
  title: "confirmação do endereço",
  street: "endereço",
  number: "número",
  complement: "complemento (opcional)",
  next: "Avançar",
  back: "voltar",
  streetPlaceholder: "Rua",
  numberPlaceholder: "0000",
  complementPlaceholder: "Casa, apartamento, bloco...",
  login:"entrar",
};

export const images = {
  pencil,
};
