import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { AddressesUserActions } from "state";
import { color_theme } from "../../color-theme";
import { images } from "./constants";

const AddressSearchResults = ({ address, handleClick }: any) => {
  const dispatch = useDispatch();

  const toggleFavorite = (address: any) => {
    dispatch(AddressesUserActions.toggleFavorite(address));
  };

  const selectedAddress = () => {
    handleClick(address);
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "81px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid black",
        p: "16px 32px 16px 32px",
        zIndex: 999,
        backgroundColor: color_theme.background.white.main,
      }}
    >
      <Box onClick={selectedAddress}>
        <Typography
          sx={{
            width: "100%",
            fontSize: 16,
            fontWeight: 600,
            lineHeight: "19.5px",
            fontFamily: "Montserrat",
            mb: "7px",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
          }}
        >
          {address.structured_formatting.main_text}
        </Typography>
        <Typography
          sx={{
            width: "50%",
            fontSize: 12,
            fontWeight: 300,
            lineHeight: "14.63px",
            fontFamily: "Montserrat",
            marginRight: "8px",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
          }}
        >
          {address.structured_formatting.secondary_text}
        </Typography>
      </Box>
      <img
        src={address.isFavorite ? images.FavSelected : images.Fav}
        style={{
          height: "21px",
          objectFit: "cover",
          position: "relative",
          padding: 0,
          zIndex: 1,
        }}
        onClick={() => toggleFavorite(address)}
        alt="Favoritar endereço"
      />
    </Box>
  );
};
export default AddressSearchResults;
