import { FC, useMemo } from "react";
import {
  Container,
  SectionContainer,
  SectionLabel,
  SectionValue,
} from "./styles";

const Section = ({ value, label }: any) => {
  return (
    <SectionContainer>
      <SectionValue>{value.toString().padStart(2, "0")}</SectionValue>
      <SectionLabel>{label}</SectionLabel>
    </SectionContainer>
  );
};

const Separator = () => {
  return <div>:</div>;
};

interface Props {
  countdownSeconds?: number;
}

const CountdownTimer: FC<Props> = ({ countdownSeconds }) => {
  const { days, hours, minutes, seconds } = useMemo(() => {
    let days = 7,
      hours = 0,
      minutes = 0,
      seconds = 0;

    if (!countdownSeconds) return { days, hours, minutes, seconds };

    const SECONDS_IN_DAY = 60 * 60 * 24;
    const SECONDS_IN_HOUR = 60 * 60;
    const SECONDS_IN_MINUTE = 60;

    days = Math.floor(countdownSeconds / SECONDS_IN_DAY);
    hours = Math.floor((countdownSeconds % SECONDS_IN_DAY) / SECONDS_IN_HOUR);
    minutes = Math.floor(
      (countdownSeconds % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE
    );
    seconds = countdownSeconds % SECONDS_IN_MINUTE;

    return { days, hours, minutes, seconds };
  }, [countdownSeconds]);

  return (
    <Container>
      <Section value={days} label="dias" />
      <Separator />
      <Section value={hours} label="horas" />
      <Separator />
      <Section value={minutes} label="min" />
      <Separator />
      <Section value={seconds} label="seg" />
    </Container>
  );
};

export default CountdownTimer;
