import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import * as yup from "yup";

import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

import { useNavigate } from "react-router-dom";

import {
  ErrorMessage,
  Label,
  Layout,
  NavigationButtons,
  TextInput,
} from "components";
import { errorMessages } from "config";
import { useAuth } from "hooks";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, userActions } from "state";
import { color_theme } from "../../color-theme";
import { texts } from "./constants";

const schema = yup
  .object({
    email: yup
      .string()
      .trim()
      .email(errorMessages.emailFormat)
      .required(errorMessages.emailRequired),
  })
  .required();

const useStyles = makeStyles({
  link: {
    marginLeft: 4,
    color: color_theme.text.black.main,
  },
});

const Login = () => {
  const classes = useStyles();
  const { login, loading } = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { autoRegister } = useSelector((state: RootState) => ({
    autoRegister: state.storeConfig.auto_register,
  }));

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data: any) => {
    data.email = data.email.toLowerCase();
    const res = await login(data);

    if (res.error) setError(res.message);
    else {
      dispatch(userActions.setEmail(data.email));
      dispatch(userActions.setNumberLastDigits(res.user.number_last_digits));
      // @ts-ignore
      navigate("/confirmation-sms", { state });
    }
  };

  const sacContact = `${texts.inveteRegister.whatsappUrl}${encodeURI(
    texts.inveteRegister.whatsappMessage
  )}`;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <Layout>
        <Box
          sx={{
            mt: 1,
            maxHeight: 700,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
          }}
        >
          <Box width="100%">
            <Label>{texts.loginTitle}</Label>
            <TextInput
              control={control}
              error={errors["email"]}
              name="email"
              autoComplete="email"
              placeholder={texts.emailPlaceholder}
              required
            />
            <ErrorMessage text={error} />

            <Box
              mt={4}
              justifyContent="center"
              sx={{
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              {autoRegister ? (
                <>
                  {texts.autoRegister.noAccount}
                  <Link to="/signup" className={classes.link}>
                    {texts.autoRegister.noAccountLink}
                  </Link>
                </>
              ) : (
                <>
                  {texts.inveteRegister.noAccount}{" "}
                  <br style={{ marginBottom: 7 }} />
                  {texts.inveteRegister.remember}
                  <a
                    href={sacContact}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                    style={{ fontWeight: 700 }}
                  >
                    {texts.inveteRegister.rememberLink}
                  </a>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Layout>
      <NavigationButtons
        rightTitle={texts.login}
        rightProps={{ type: "submit" }}
        rightLoading={loading}
      />
    </form>
  );
};

export default Login;
