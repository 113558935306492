import { Button, Grid } from "@mui/material";
import { useShoppingCart } from "hooks";
import { FC } from "react";
import { color_theme } from "../../color-theme";
import { images } from "./constants";

interface CounterInputProps {
  quantity: number;
  id: number;
  disabled?: boolean;
  disableAdd?: boolean;
  clearWhenAdding?: boolean;
}

const CounterInput: FC<CounterInputProps> = ({
  quantity,
  id,
  disabled,
  disableAdd,
  clearWhenAdding = false,
}) => {
  const { addProduct, removeProduct } = useShoppingCart();

  let color = disabled
    ? color_theme.text.black.disabled
    : color_theme.text.black.main;

  const handleClickUp = () => {
    addProduct(id);
  };

  const handleClickDown = () => {
    removeProduct(id);
  };

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "41px",
        ml: 3,
      }}
    >
      <Button
        disabled={disabled || disableAdd}
        sx={{
          padding: "10px",
          minWidth: 0,
        }}
        onClick={handleClickUp}
      >
        <img
          src={disabled || disableAdd ? images.arrowUpDisabled : images.arrowUp}
          style={{
            objectFit: "cover",
            height: "15px",
          }}
          alt="adicionar produto"
        />
      </Button>

      <p
        style={{
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "22px",
          lineHeight: "27px",
          textAlign: "center",
          color: color,
        }}
      >
        {quantity}
      </p>
      <Button
        disabled={quantity === 0 || disabled}
        sx={{
          padding: "10px",
          minWidth: 0,
        }}
        onClick={handleClickDown}
      >
        <img
          src={
            quantity === 0 || disabled
              ? images.arrowDownDisabled
              : images.arrowDown
          }
          style={{
            height: "15px",
            objectFit: "cover",
          }}
          alt="remover item"
        />
      </Button>
    </Grid>
  );
};
export default CounterInput;
