import add from "assets/add.svg";
import creditCard from "assets/credit-card.svg";
import closeIcon from "assets/closeIcon.svg";
import pix from "assets/pix.svg";

export const images = {
  add,
  creditCard,
  closeIcon,
  pix,
};
