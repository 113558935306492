import arrowUp from "assets/ArrowUp.svg";
import arrowDown from "assets/ArrowDown.svg";
import arrowDownDisabled from "assets/arrowDownDisabled.svg";
import arrowUpDisabled from "assets/arrowUpDisabled.svg";

export const images = {
  arrowUp,
  arrowDown,
  arrowDownDisabled,
  arrowUpDisabled,
};
