import arrowDown from "assets/arrowDown2.svg";
import arrowUp from "assets/arrowUp2.svg";

export const texts = {
  screenTitle: "histórico de pedidos",
  next: "fazer pedido",
  infoDeliveryTitle: "informações para entrega",
  recipient: "entregar para",
  address: "Endereço da entrega",
};

export const images = {
  arrowDown,
  arrowUp,
};
