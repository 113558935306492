import { LoadingContainer } from "./styles";

interface LoadingProps {
  isBlack?: boolean;
}
const FumicoLoading = ({ isBlack }: LoadingProps) => {
  return (
    <LoadingContainer isBlack={isBlack}>
      <div className="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoadingContainer>
  );
};

export default FumicoLoading;
