import {createSlice} from '@reduxjs/toolkit';

interface InitialState {
  loading: boolean;
}

const initialState: InitialState = {
  loading: false,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {actions, reducer} = loadingSlice;
