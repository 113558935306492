import { FC } from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import ProgressBar from "./ProgressBar";
import CustomStepIcon from "./CustomStepIcon";

interface StepperStatusProps {
  steps: any[];
  activeStep: number;
  orderStatus: string;
}

const StepperStatus: FC<StepperStatusProps> = ({
  steps,
  activeStep,
  orderStatus,
}) => {
  const getLineColor = (stepToCompare: number) => {
    return orderStatus !== "REJECTED" && orderStatus !== "CANCELLED"
      ? activeStep >= stepToCompare
        ? "#0AC499"
        : "#ABABAB"
      : "#FA3D3A";
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          margin: "-24px 0 24px 0",
          fontFamily: "Montserrat",
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: "20px",
        }}
      >
        {steps[activeStep]}
      </div>

      <Stepper
        sx={{ width: "calc(100% + 64px)", marginLeft: "-16%" }}
        activeStep={activeStep}
        alternativeLabel
        connector={<ProgressBar></ProgressBar>}
      >
        {steps?.map((label) => (
          <Step
            key={label}
            sx={{
              paddingLeft: 0,
              paddingRight: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              "& span": {
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "12px",
              },
              "&:first-child": { marginRight: "-11%" },

              "&:nth-child(2) .progress": {
                backgroundColor: getLineColor(1),
              },
              "&:nth-child(2) .progress-bar": {
                backgroundColor: getLineColor(0),
              },

              "&:nth-child(3) .progress": {
                backgroundColor: getLineColor(2),
              },
              "&:nth-child(3) .progress-bar": {
                backgroundColor: getLineColor(1),
              },

              "&:nth-child(4) .progress": {
                backgroundColor: getLineColor(3),
              },
              "&:nth-child(4) .progress-bar": {
                backgroundColor: getLineColor(2),
              },
            }}
          >
            <StepLabel
              StepIconComponent={(stepProps: {}) => (
                <CustomStepIcon
                  stepProps={stepProps}
                  orderStatus={orderStatus}
                ></CustomStepIcon>
              )}
              sx={{
                "& .Mui-completed": {
                  color: "#0AC499",
                },
                "& .Mui-active": {
                  color: "#0AC499",
                },
              }}
            ></StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export default StepperStatus;
