// @ts-nocheck
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import {
  ErrorMessage,
  Layout,
  NavigationButtons,
  ScreenTitle,
  TextInput,
} from "components";
import { errorMessages } from "config";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AnimatePage from "routing/AnimatedPage";
import { geolocationActions, RootState } from "state";
import * as yup from "yup";
import { images, texts } from "./constants";

import useMap from "hooks/useMap";

const schema = yup
  .object({
    number: yup.string().required(errorMessages.numberRequired),
  })
  .required();

const AddressComplement = () => {
  // const completeAddressData = useSelector(
  //   (state: RootState) => state.geolocation.selectedAddress
  // );

  const loggedIn = useSelector((state: RootState) => !!state.auth.token);

  const address = useSelector(
    (state: RootState) =>
      state.geolocation.selectedAddress.structured_formatting.main_text
        .address ||
      state.geolocation.selectedAddress.structured_formatting.main_text
  );
  const addressNumber = useSelector(
    (state: RootState) => state.geolocation.selectedAddress.addressNumber
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addressError, setAddressError] = useState("");
  const { goToMap } = useMap();

  useEffect(() => {
    // TODO ...
    // const structured_address = geocode (request do google)
    // setValue("street", street)
    // setValue("number", number)
    // setValue("comeplement", complement)
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      number: addressNumber
        ? addressNumber
        : parseInt(address.split(",")[1]) || "",
      complement: "",
    },
  });

  const streetName = address.split(",")[0];

  // const { geocode, getAddressFromLatLng } = useGeocoder();

  // const setZipcode = async (searchQuery) => {
  //   console.log("searching zipcode for", searchQuery);

  //   const coordinates = await geocode(searchQuery);

  //   const { lat, lng } = coordinates;

  //   console.log("lat lng", { lat, lng });

  //   const addressFromLatLng = await getAddressFromLatLng(
  //     lat.toString(),
  //     lng.toString()
  //   );

  //   const payload = {
  //     ...completeAddressData,
  //     coordinates,
  //     zipcode: addressFromLatLng.zipcode,
  //   };

  //   dispatch(geolocationActions.setSelectedAddress(payload));
  // };

  const onSubmit = (data: any) => {
    // const searchQuery = `${streetName}, ${data.number}`;
    // setZipcode(searchQuery);

    if (!streetName) {
      setAddressError("Endereço não pode estar vazio");
      return;
    }

    setAddressError("");
    const addressDescription = `${streetName}, ${data.number} ${data.complement}`;

    dispatch(
      geolocationActions.setAddressComplement({
        description: addressDescription,
        number: data.number,
        complement: data.complement,
      })
    );

    // @ts-ignore
    navigate(loggedIn ? "/order-confirmation" : "/login", {
      state: { nextRoute: "/order-confirmation" },
    });
  };

  const goToAddressSelection = () => {
    goToMap("/address-complement");
  };

  const goBack = () => {
    navigate("/catalog");
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <Layout>
        <AnimatePage>
          <Box
            sx={{
              mt: 1,
              maxHeight: 700,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
              width: "100%",
            }}
          >
            <Box width="100%">
              <ScreenTitle>{texts.title}</ScreenTitle>
              <Box mt={5} mb={5}>
                <ScreenTitle>{texts.street}</ScreenTitle>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 300,
                      fontSize: "16px",
                      mt: 1,
                      lineHeight: "20px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {streetName}
                  </Typography>
                  <img
                    src={images.pencil}
                    onClick={goToAddressSelection}
                    alt="alterar endereço"
                  />
                </Box>
                <ErrorMessage text={addressError} />
              </Box>
              <ScreenTitle>{texts.number}</ScreenTitle>
              <TextInput
                control={control}
                error={errors["number"]}
                name="number"
                autoComplete="number"
                placeholder={texts.numberPlaceholder}
                type="number"
              />
              <ScreenTitle>{texts.complement}</ScreenTitle>
              <TextInput
                control={control}
                // error={errors["complement"]}
                name="complement"
                autoComplete="complement"
                placeholder={texts.complementPlaceholder}
              />
            </Box>
          </Box>
        </AnimatePage>
      </Layout>
      <NavigationButtons
        rightTitle={loggedIn ? texts.next : texts.login}
        rightProps={{ type: "submit" }}
        leftTitle={texts.back}
        leftAction={goBack}
      />
    </form>
  );
};

export default AddressComplement;
