import brandingLogo from "assets/logoRELX.png";
import { Container } from "./styles";

const Header = () => {
  return (
    <Container>
      <img src={brandingLogo} alt="fumi.co" />
    </Container>
  );
};

export default Header;
