import { GoogleMap } from "@react-google-maps/api";

import { GOOGLE_MAPS_API_KEY } from "config";

import { Box, CircularProgress } from "@mui/material";
import { Marker } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/src/utils/make-load-script-url";
import { useAddressList, useGeocoder } from "hooks";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, apiActions, geolocationActions } from "state";
import { isInServiceArea } from "../../utils/isInServiceArea";
import { GoogleMapsProvider } from "./GoogleMapsProvider";
import { images } from "./constants";

interface GoogleMapsProps {
  setDisableButton: any;
}
const containerStyle = {
  width: "100%",
  height: "100%",
};

const googleMapsLibraries: Libraries = ["places"];

const GoogleMaps: FC<GoogleMapsProps> = ({ setDisableButton }) => {
  const dispatch = useDispatch();
  const isLoaded = useSelector((state: RootState) => state.map.isLoaded);
  const { getAddressFromLatLng } = useGeocoder();
  const latLng = useSelector(
    (state: RootState) => state.geolocation.selectedAddress.coordinates
  );
  const { updateSearchQuery } = useAddressList();

  const handleChange = async (e: any) => {
    const latitude = e.latLng.lat();
    const longitude = e.latLng.lng();
    try {
      const address = await getAddressFromLatLng(latitude, longitude);

      setDisableButton(false);
      updateSearchQuery(address.address);
      dispatch(
        geolocationActions.setLocale({
          lat: latitude,
          lng: longitude,
          nameAddress: address.address,
          addressCityState: `${address.city} - ${address.state}`,
          zipcode: address.zipcode,
          street: address.street,
          number: address.number,
          district: address.district,
          city: address.city,
          state: address.state,
        })
      );

      dispatch(apiActions.setApiFromState(address.state));

      console.log("google maps @ handle change", { address });

      const outOfServiceArea = !isInServiceArea(address.city, { strict: true });

      if (outOfServiceArea) {
        setDisableButton(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <GoogleMapsProvider
      googleMapsApiKey={GOOGLE_MAPS_API_KEY}
      libraries={googleMapsLibraries}
    >
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={latLng}
          zoom={15}
          options={{
            disableDefaultUI: true,
            clickableIcons: false,
            zoomControlOptions: {
              position: google.maps.ControlPosition.LEFT_CENTER,
            },
          }}
          onClick={handleChange}
        >
          <Marker
            icon={images.marker}
            draggable
            onDragEnd={handleChange}
            position={latLng}
          />
        </GoogleMap>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            paddingBottom: "20%",
            height: "100%",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
    </GoogleMapsProvider>
  );
};

export default GoogleMaps;
