import { endpoints } from "config";
import useRequest from "./useRequest";

const useInvite = () => {
  const { post } = useRequest();

  const createInvite = async (payload: any) => {
    const res = await post(`${endpoints.invite}`, payload);

    return res;
  };
  return { createInvite };
};

export default useInvite;
