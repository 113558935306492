import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  active: boolean;
  promo?: string;
}

const initialState: InitialState = {
  active: false,
  promo: undefined,
};

const activePromoSlice = createSlice({
  name: "activePromo",
  initialState,
  reducers: {
    activatePromo: (state, action: PayloadAction<string>) => {
      state.active = true;
      state.promo = action.payload;
    },
    disablePromo: (state) => {
      state.active = false;
      state.promo = undefined;
    },
  },
});

export const { actions, reducer } = activePromoSlice;
