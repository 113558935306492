import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  // addresses: AddressType[];
  addresses: any[];
  lastAddresses: any[];
}

const initialState: InitialState = {
  addresses: [],
  lastAddresses: [],
};

const favoriteAddressesSlice = createSlice({
  name: "addressesUser",
  initialState,
  reducers: {
    toggleFavorite: (state, action) => {
      const address = action.payload;

      const index = state.addresses.findIndex(
        (item) => item.place_id === address.place_id
      );

      // remove se ja é favorito
      if (index >= 0) {
        state.addresses.splice(index, 1);
      } else {
        state.addresses.push({ ...address, isFavorite: true });
      }
    },
    setLastadresses: (state, action) => {
      state.lastAddresses = action.payload;
    },
  },
});

export const { actions, reducer } = favoriteAddressesSlice;
