import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  open: boolean;
}

const initialState: InitialState = {
  open: false,
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { actions, reducer } = menuSlice;
