import { createSlice } from "@reduxjs/toolkit";

type ProductWithPrice = ProductType & { fullprice: number; subtotal: number };

interface InitialState {
  products: ProductWithPrice[];
  selected: ProductType | null;
  fullprice: number;
  subtotal: number;
  shipping: number;
  calculated_discount: number;
  fullprice_integral?: number;
  subtotal_integral?: number;
  recipientName?: string;
  recipientDescription?: string;
  observation?: string;
  updatePricing: number; // usado para fazer o request de preço ao mudar os produtos
  coupon: string | undefined;
  couponError: boolean | undefined;
  discount_from?: string;
  pixData: {
    id: null | string;
    status: null | string;
    pixQRCode: null | string;
    pixQRCodeUrl: null | string;
    pixExpirationDate: null | string;
  };
  lastUserAccess: number;
  is_promo_eligible?: Array<string>;
}

const initialState: InitialState = {
  products: [],
  selected: null,
  fullprice: 0,
  subtotal: 0,
  shipping: 0,
  fullprice_integral: 0,
  subtotal_integral: 0,
  calculated_discount: 0,
  recipientName: "",
  recipientDescription: "",
  observation: "",
  updatePricing: 0,
  coupon: undefined,
  couponError: undefined,
  discount_from: undefined,
  pixData: {
    id: null,
    status: null,
    pixQRCode: null,
    pixQRCodeUrl: null,
    pixExpirationDate: null,
  },
  lastUserAccess: 0,
  is_promo_eligible: [],
};

const ShoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      const selected = { ...action.payload };
      const index = state.products.findIndex((item) => item.id === selected.id);
      if (index !== -1) {
        selected.quantity = state.products[index].quantity;
      }
      state.selected = selected;
    },
    setRecipient: (state, action) => {
      const { recipientName, recipientDescription } = action.payload;
      state.recipientName = recipientName;
      state.recipientDescription = recipientDescription;
    },
    setObservation: (state, action) => {
      state.observation = action.payload;
    },
    setCupom: (state, action) => {
      state.coupon = action.payload;
    },
    setCouponError: (state) => {
      state.couponError = true;
    },
    cleanShoppingCart: () => {
      return {
        ...initialState,
        lastUserAccess: Date.now(),
      };
    },
    setQuantity: (state, action) => {
      const { id, quantity, product } = action.payload;
      const index = state.products.findIndex((item) => item.id === id);
      state.updatePricing++;

      if (index >= 0) {
        if (parseInt(quantity) <= 0 || !quantity) {
          state.products.splice(index, 1);
          return;
        }
        state.products[index].quantity = quantity;
      } else {
        if (parseInt(quantity) <= 0 || !quantity) return;
        state.products.push({
          ...product,
          id,
          quantity,
        });
      }
    },
    incrementQuantity: (state, action) => {
      const { id, change, product } = action.payload;
      let index = state.products.findIndex((item) => item.id === id);
      state.updatePricing++;

      let value = (state.products[index]?.quantity || 0) + change;
      if (value < 0) value = 0;
      if (value > 1000) value = 1000;

      if (value === 0) {
        state.products.splice(index, 1);
      } else if (index >= 0) {
        state.products[index].quantity = value;
      } else {
        state.products.push({
          ...product,
          id,
          quantity: value,
        });
      }
    },
    setPricing: (state, action) => {
      // 1. para cada produto, pega seu valor "fullprice" e "subtotal"
      const pricing = action.payload;
      if (state.subtotal !== pricing?.total?.subtotal) {
        // zera informacoes de pagamento apenas caso tenha sido alterado valor de subtotal
        state.pixData = initialState.pixData;
      }

      state.products.forEach((product, index) => {
        state.products[index].fullprice_integral =
          pricing.products[product.id]?.fullprice_integral;
        state.products[index].subtotal_integral =
          pricing.products[product.id]?.subtotal_integral;
        state.products[index].fullprice =
          pricing.products[product.id]?.fullprice;
        state.products[index].subtotal = pricing.products[product.id]?.subtotal;
      });

      // 2. para o valor total, coloca no state do proprio carrinho
      state.fullprice = pricing?.total?.fullprice;
      state.subtotal = pricing?.total?.subtotal;
      state.shipping = pricing?.total?.shipping;
      state.calculated_discount = pricing?.total?.calculated_discount;
      state.discount_from = pricing?.discount_from;
      state.is_promo_eligible = pricing?.is_promo_eligible;

      // referencia pra preço integral...
      state.fullprice_integral = pricing?.total?.fullprice_integral;
      state.subtotal_integral = pricing?.total?.subtotal_integral;
    },
    setPixData: (state, action) => {
      const payload = action.payload || initialState.pixData;
      Object.assign(state.pixData, payload);
    },
    checkLastUserAccess: (state) => {
      if (state.products.length === 0) {
        state.lastUserAccess = Date.now();
      }
    },
  },
});

export const { actions, reducer } = ShoppingCartSlice;
