import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Box from "@mui/material/Box";

import { useNavigate } from "react-router-dom";

import {
  TextInput,
  Layout,
  ScreenTitle,
  NavigationButtons,
  ErrorMessage,
} from "components";
import { errorMessages } from "config";
import { useAuth } from "hooks";

import { texts } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState, shoppingCartActions } from "state";
import AnimatedPage from "routing/AnimatedPage";

const schema = yup
  .object({
    recipientName: yup
      .string()
      .required(errorMessages.nameRequired)
      .min(3, errorMessages.nameMinLength),
    recipientDescription: yup.string(),
  })
  .required();

const ChooseRecipient = () => {
  const { loading, errorMessage } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recipientName = useSelector((state: RootState) => state.user.name);
  const loggedIn = useSelector((state: RootState) => !!state.auth.token);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      recipientName,
      recipientDescription: "",
    },
  });

  const onSubmit = (data: any) => {
    dispatch(shoppingCartActions.setRecipient(data));
    if (!loggedIn) {
      navigate("/login", { state: { nextRoute: "/order-confirmation" } });
    } else {
      navigate("/order-confirmation");
    }
  };

  const back = () => {
    navigate("/maps-local");
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <Layout>
        <AnimatedPage>
          <Box
            sx={{
              mt: 1,
              maxHeight: 700,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
              width: "100%",
            }}
          >
            <Box width="100%" fontFamily="Montserrat">
              <ScreenTitle>{texts.recipientName}</ScreenTitle>
              <ErrorMessage text={errorMessage} />

              <TextInput
                value={recipientName}
                control={control}
                error={errors["recipientName"]}
                name="recipientName"
                autoComplete="recipientName"
                required
              />
              <ScreenTitle>{texts.recipientDescription}</ScreenTitle>
              <ErrorMessage text={errorMessage} />

              <TextInput
                control={control}
                error={errors["recipientDescription"]}
                name="recipientDescription"
                autoComplete="recipientDescription"
                placeholder={texts.descriptionPlaceholder}
                required
              />
            </Box>
          </Box>
        </AnimatedPage>
      </Layout>

      <NavigationButtons
        leftTitle={texts.back}
        leftAction={back}
        rightTitle={!loggedIn ? texts.login : texts.next}
        rightProps={{ type: "submit" }}
        leftDisabled={loading}
        rightLoading={loading}
      />
    </form>
  );
};

export default ChooseRecipient;
