import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import {
  ErrorMessage,
  Layout,
  NavigationButtons,
  NumberPhoneDDI,
} from "components";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { texts } from "./constants";
import * as yup from "yup";
import { errorMessages } from "config";
import useUser from "hooks/useUser";
import { useDispatch, useSelector } from "react-redux";
import { RootState, userActions } from "state";
import { useState } from "react";
import { useRequest } from "hooks";
import LoadingOverlay from "screens/OrderConfirmation/LoadingOverlay";

const schema = yup
  .object({
    phone: yup
      .string()
      .trim()
      .required(errorMessages.phoneRequired)
      .min(11, errorMessages.phoneMinLength)
      .max(14, errorMessages.phoneMaxLength)
      .nullable(),
  })
  .required();

const UpdateUserPhone = () => {
  const [fumicoLoading, setFumicoLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const { loading, setLoading } = useRequest();
  const { patchPhone } = useUser();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: "",
    },
  });

  const onSubmit = async (data: any) => {
    const payload = {
      phone: data.phone,
    };
    setLoading(true);
    setFumicoLoading(true);
    const res = await patchPhone(payload, user.id ? user.id : "");
    if (res) setError(res?.message);
    if (!res.error) {
      dispatch(userActions.setUserNumber(data.phone));
      navigate("/userprofile");
      setFumicoLoading(false);
      setLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {fumicoLoading && <LoadingOverlay />}
        <Layout>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "17px",
                mb: 2,
                fontFamily: "Montserrat",
              }}
            >
              {texts.newPhone}
            </Typography>
            <NumberPhoneDDI
              errors={errors["phone"]}
              control={control}
              placeholder={texts.numberPlaceholder}
            />
            <ErrorMessage text={error}></ErrorMessage>
          </Box>
        </Layout>
        <NavigationButtons
          leftTitle={texts.cancel}
          leftAction={() => navigate("/userprofile")}
          rightTitle={texts.next}
          rightProps={{ type: "submit" }}
          rightLoading={loading}
        ></NavigationButtons>
      </form>
    </>
  );
};

export default UpdateUserPhone;
