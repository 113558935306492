import Box from "@mui/material/Box";
import TagManager from "react-gtm-module";

import {
  CarouselPromo,
  CatalogFilter,
  Layout,
  NavigationButtons,
  ShoppingCart,
} from "components";
import { useBanners, useOrders, useProducts, useShoppingCart } from "hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "routing/AnimatedPage";
import { RootState } from "state";
import FumicoLoading from "../../components/FumicoLoading";
import { getStoreConfig } from "../../state/ducks/store-config";
import Category from "./Category";
import EmptyStore from "./EmptyStore";
import ProductItem from "./ProductItem";
import ProductPromo from "./ProductPromo";
import SearchProducts from "./SearchProducts";
import { texts } from "./constants";

const ProductCatalog = () => {
  const [search, setSearch] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [categorySelected, setCategorySelected] = useState("all");
  const navigate = useNavigate();
  const { getProducts, loading } = useProducts();
  const products = useSelector((state: RootState) => state.products.products);
  const { acessoApp, loading: pageLoading } = useSelector(
    (state: RootState) => state.products
  );
  const productsInShoppingCart = useSelector(
    (state: RootState) => state.shoppingCart.products
  );
  const { store_is_open: activeStore } = useSelector(getStoreConfig);
  const [subcategorySelected, setSelected] = useState("all");
  const [openModal, setOpenModal] = useState(false);
  const { cartQuantities, clearShoppingCart } = useShoppingCart();

  const isActive = useSelector((state: RootState) => state.activeOrder.order);
  const activePromo = useSelector(
    (state: RootState) => state.activePromo.active
  );
  const orders = useSelector((state: RootState) => state.orders.orders);
  const categories = useSelector(
    (state: RootState) => state.products.categories
  );

  const { getOrders } = useOrders();
  const { getBanners } = useBanners();

  const _setCategorySelected = (params: any) => {
    // setSearch("");
    // setSearchProduct("");
    setCategorySelected(params);
  };

  const _setSelected = (params: any) => {
    // setSearch("");
    // setSearchProduct("");
    setSelected(params);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const navigateBack = () => {
    navigate("/");
  };

  const navigateNext = () => {
    const filterFinishedOrders = orders?.filter(
      (order) => order.status === "FINISHED"
    );

    if (activePromo && filterFinishedOrders?.length > 0) {
      clearShoppingCart();
      navigate("/promo/not-available");
    } else {
      navigate("/address-complement");
    }
  };

  useEffect(() => {
    getBanners();
    getOrders();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!products?.length) {
      getProducts();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!activePromo) {
      getProducts();
    }

    // eslint-disable-next-line
  }, [activePromo]);

  useEffect(() => {
    if (isActive?.status === "DELIVERING" || isActive?.status === "APPROVED") {
      navigate("/order-confirmation-status");
    }
  }, [isActive?.status, navigate]);

  let filteredProducts = useMemo(() => {
    let selected = products;
    let filteredCategories: any = [];
    let _filteredCategory: any[] = [];
    let _filteredSubCategory: any[] = [];

    if (!!searchProduct) {
      selected = selected.filter((prod) =>
        prod.name.toLowerCase().includes(searchProduct.toLowerCase())
      );

      if (subcategorySelected !== "all") {
        selected = selected.filter(
          (prod) => prod.subcategory === subcategorySelected
        );
      }
    } else {
      if (categorySelected === "promoções") {
        selected = selected.filter(
          (prod) => prod.promo_price > 0 || prod.category === categorySelected
        );
      }

      if (categorySelected !== "all" && categorySelected !== "promoções") {
        selected = selected.filter(
          (prod) => prod.category === categorySelected
        );
      }

      if (subcategorySelected !== "all") {
        selected = selected.filter(
          (prod) => prod.subcategory === subcategorySelected
        );
      }
    }

    selected = selected.slice().sort((a, b) => {
      return b.has_stock - a.has_stock; // todo: refatorar isso, nao faz sentido fazer aritmetica com booleanos...
    });

    if (categorySelected === "promoções") {
      selected.sort((a, b) =>
        a.price !== a.standard_price && b.price === b.standard_price ? -1 : 1
      );
    }

    if (search) {
      selected.forEach((product) => {
        if (!_filteredCategory.includes(product.category)) {
          _filteredCategory.push(product.category);
        }
        if (!_filteredSubCategory.includes(product.subcategory)) {
          _filteredSubCategory.push(product.subcategory);
        }
      });

      categories.forEach((category: any) => {
        if (_filteredCategory.includes(category.name)) {
          // dentro do category
          const _category = {
            name: category.name,
            subcategories: category.subcategories.filter((subcategory: any) =>
              _filteredSubCategory.includes(subcategory.name)
            ),
          };

          filteredCategories.push(_category);
        }
      });
    } else {
      // adiciona a categoria fumicos ao inicio da lista
      categories.forEach((category: any) => {
        if (category.name === "descartáveis") {
          filteredCategories.unshift(category);
        } else {
          filteredCategories.push(category);
        }
      });
    }
    return { selected, filteredCategories };

    // eslint-disable-next-line
  }, [products, categorySelected, subcategorySelected, searchProduct]);

  const scrollRef = useRef<any>(null);
  const scrollToProduct = () => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const { selected, filteredCategories } = filteredProducts;

  const hasActivePromo = useSelector(
    (state: RootState) => state.activePromo.active
  );
  const { selected: promoSubcategorySelected } = useSelector(
    (state: RootState) => state.promo.welcome
  );

  useEffect(() => {
    if (hasActivePromo) {
      setCategorySelected("promo <welcome>");
      _setSelected(promoSubcategorySelected);
    }

    // eslint-disable-next-line
  }, [hasActivePromo]);

  useEffect(() => {
    if (!products.length) return;

    const viewItemArgs = {
      dataLayer: {
        event: "view_item",
        currency: "BRL",
        value: products[0].price,
        items: [{ item_id: products[0].sku }],
      },
    };

    TagManager.dataLayer(viewItemArgs);
  }, [products]);

  return (
    <>
      <Layout style={{ padding: 0, fontFamily: "Montserrat" }}>
        {activeStore ? (
          <>
            <AnimatedPage>
              {!pageLoading && (
                <>
                  {!acessoApp && !hasActivePromo && (
                    <CarouselPromo
                      setCategory={setCategorySelected}
                      setSubcategory={setSelected}
                      scrollTo={scrollToProduct}
                    />
                    // <BannerPromo
                    //   setCategory={setCategorySelected}
                    //   setSubCategory={setSelected}
                    //   setSearch={setSearch}
                    //   setSearchProduct={setSearchProduct}
                    //   scrollTo={scrollToProduct}
                    // />
                  )}

                  {!hasActivePromo ? (
                    <SearchProducts
                      setCategory={setCategorySelected}
                      setSubCategory={setSelected}
                      search={search}
                      setSearch={setSearch}
                      setSearchProduct={setSearchProduct}
                    />
                  ) : (
                    <div style={{ marginTop: "40px " }} />
                  )}

                  <div
                    ref={scrollRef}
                    style={{ position: "relative", top: "-24px" }}
                  />

                  {!acessoApp && (
                    <>
                      {/* filtro de categoria */}
                      <Category
                        setSelected={_setSelected}
                        categorySelected={categorySelected}
                        setCategorySelected={_setCategorySelected}
                        categories={filteredCategories}
                        hideSelectAll={hasActivePromo}
                      />
                      {/* filtro de subcategoria */}
                      <CatalogFilter
                        selected={subcategorySelected}
                        setSelected={_setSelected}
                        categorySelected={categorySelected}
                        setCategorySelected={_setCategorySelected}
                        categories={filteredCategories}
                        hideSelectAll={hasActivePromo}
                      />{" "}
                    </>
                  )}
                </>
              )}

              <Box
                width="100%"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  px: 4,
                  py: 5,
                }}
              >
                {loading && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                      paddingBottom: "20%",
                    }}
                  >
                    <FumicoLoading isBlack={true} />
                  </Box>
                )}
                {selected.length || loading ? (
                  selected.map((product) => (
                    <div key={product.id}>
                      <ProductItem
                        {...product}
                        quantity={cartQuantities[product.id] || 0}
                      />
                      <Box
                        sx={{
                          position: "relative",
                          width: "100vw",
                          left: -32,
                          borderBottom: "1px solid #000000",
                          my: 3,
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </Box>
              {openModal ? (
                <ProductPromo open={openModal} handleClose={handleCloseModal} />
              ) : null}
            </AnimatedPage>
            <ShoppingCart />
          </>
        ) : (
          <div
            style={{ height: "100vh", display: "flex", alignItems: "center" }}
          >
            <EmptyStore />
          </div>
        )}
      </Layout>
      <NavigationButtons
        leftTitle={texts.back}
        leftAction={navigateBack}
        rightTitle={texts.next}
        rightAction={navigateNext}
        rightDisabled={productsInShoppingCart.length <= 0 || !activeStore}
      />
    </>
  );
};

export default ProductCatalog;
