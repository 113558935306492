import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  selectedAddress: AddressType;
}

const initialState: InitialState = {
  selectedAddress: {
    addressCityState: "Curitiba",
    // addressNeighborhood: "Centro",
    addressStreet: "Rua Pedro Ivo, 462, 80010-020",
    isFavorite: false,
    description: "Rua Pedro Ivo, 462, 80010-020",
    coordinates: { lat: -25.4335108, lng: -49.269379 },
    structured_formatting: {
      main_text: "",
    },
    addressNumber: null,
    addressComplement: "",
    zipcode: "",
  },
};

const geolocationSlice = createSlice({
  name: "geolocation",
  initialState,
  reducers: {
    setLocale: (state, action) => {
      const {
        lat,
        lng,
        nameAddress,
        zipcode,
        addressCityState,
        street,
        number,
        district,
        city,
        state: stateShort,
      } = action.payload;
      console.log("setLocale", action.payload);
      state.selectedAddress.coordinates.lat = lat;
      state.selectedAddress.coordinates.lng = lng;
      state.selectedAddress.addressCityState = addressCityState;
      state.selectedAddress.structured_formatting.main_text = nameAddress;
      state.selectedAddress.addressStreet = nameAddress;
      state.selectedAddress.description = nameAddress;
      state.selectedAddress.zipcode = zipcode;
      state.selectedAddress.addressNumber =
        parseInt(nameAddress.split(",")[1]) || "";
      state.selectedAddress.street = street;
      state.selectedAddress.number = number;
      state.selectedAddress.district = district;
      state.selectedAddress.city = city;
      state.selectedAddress.state = stateShort;
    },
    setSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload;

      console.log("setSelectedAddress", action.payload);
    },
    setAddressComplement: (state, action) => {
      console.log("setAddressComplement", action.payload);
      state.selectedAddress.description = action.payload.description;
      state.selectedAddress.addressStreet = action.payload.description;
      state.selectedAddress.addressNumber = action.payload.number;
      state.selectedAddress.addressComplement = action.payload.complement;
    },
  },
});

export const { actions, reducer } = geolocationSlice;
