import { AnimatePresence } from "framer-motion";
import { useGoogleAnalytics } from "hooks";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { RootState } from "state";
import { routes } from "./routes";

const Routing: FC = ({ children }) => {
  const { userRoutes, guestRoutes } = routes;
  const isAuthenticated = useSelector((state: RootState) => state.auth.token);
  const activeRoutes = isAuthenticated ? userRoutes : guestRoutes;
  const location = useLocation();

  useGoogleAnalytics();

  return (
    <>
      {children}
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          {activeRoutes.map(({ Component, ...route }: any) => (
            <Route key={route.path} {...route} element={<Component />} />
          ))}
        </Routes>
      </AnimatePresence>
    </>
  );
};

export default Routing;
