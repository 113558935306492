import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { ModalDialog } from "components";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { color_theme } from "../../color-theme";

interface ModalConfirmProps {
  open: boolean;
  setOpen(value: boolean): void;
  loading: boolean;
  setLoading: any;
  setError: any;
  confirm: () => Promise<void>;
}

const ModalConfirm: FC<ModalConfirmProps> = ({
  open,
  setOpen,
  loading,
  setLoading,
  setError,
  confirm,
}) => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(false);

  const next = async () => {
    try {
      setLoading(true);
      setDisabled(true);
      setOpen(false);

      await confirm();

      navigate("/order-confirmation-status");
    } catch (err) {
      setLoading(false);
      setOpen(false);
      setError(
        "erro ao criar seu pedido :c entre em contato com o SAC no menu e resolveremos este problema"
      );
    }
  };

  return (
    <ModalDialog open={open} setOpen={setOpen}>
      <Box justifyContent="center" alignItems="center" p={4}>
        <Typography fontWeight={600}>
          Confirmar metodo de pagamento na entrega
        </Typography>
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button
            disabled={disabled}
            variant="contained"
            sx={{
              backgroundColor: color_theme.background.black.disabled,
              mr: 1,
            }}
            onClick={() => setOpen(false)}
          >
            cancelar
          </Button>
          <Button disabled={disabled} variant="contained" onClick={next}>
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "confirmar"
            )}
          </Button>
        </Box>
      </Box>
    </ModalDialog>
  );
};
export default ModalConfirm;
