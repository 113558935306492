import { Button } from "@mui/material";
import { Header } from "screens/Promo/components";
import {
  Accent,
  Container,
  Content,
  Title,
} from "screens/Promo/Welcome/styles";
import badSmile from "assets/badSmile.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { activePromoActions } from "state";

const PromoNotAvailable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToCatalog = () => {
    dispatch(activePromoActions.disablePromo());
    navigate("/catalog");
  };

  return (
    <Container style={{ height: "100%" }}>
      <Header />
      <Content
        style={{
          alignItems: "center",
        }}
      >
        <img
          src={badSmile}
          alt="promoção indisponível"
          style={{
            filter: "invert(1)",
            opacity: "0.5",
            width: "100px",
            marginBottom: "20px",
          }}
        />
        <Title>
          promoção válida apenas para primeiros pedidos, mas não vamos deixar
          você na mão, use o cupom <Accent>BLACKFRIDAY</Accent>
        </Title>
        <Button
          variant="outlined"
          color="success"
          sx={{
            marginTop: "40px",
          }}
          onClick={navigateToCatalog}
        >
          voltar ao catálogo
        </Button>
      </Content>
    </Container>
  );
};

export default PromoNotAvailable;
