import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { menuActions } from "state";
import { color_theme } from "../../color-theme";
interface HeaderBaseProps {
  center?: boolean;
}
const HeaderBase: React.FC<HeaderBaseProps> = ({ children, center }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const openMenu = () => {
    dispatch(menuActions.setOpen(true));
  };

  const screensNoMenu = ["/order-confirmation"];

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        width: "100%",
        backgroundColor: color_theme.background.black.main,
        color: color_theme.text.white.main,
        fontSize: 22,
        fontWeight: "700",
        minHeight: 120,
        pt: "58px",
        pb: "22px",
      }}
    >
      <Grid
        item
        xs={2}
        container
        justifyContent="flex-start"
        sx={{ px: 3, cursor: "pointer" }}
        onClick={openMenu}
      >
        {screensNoMenu.find((path) => path === location.pathname) ? (
          <></>
        ) : (
          <MenuIcon />
        )}
      </Grid>

      <Grid
        sx={{
          flex: 1,
          display: "flex",
          fontFamily: "Spartan",
          letterSpacing: "-0.075em",
          ml: center ? 5 : 0,
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default HeaderBase;
