import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";

import { FC } from "react";
interface ModalDialogProps {
  open: boolean;
  setOpen?: any;
  width?: string;
}

const ModalDialog: FC<ModalDialogProps> = ({
  children,
  open,
  setOpen,
  width,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width ? width : "375px",
    bgcolor: "background.paper",
    outline: "none",
  };

  return (
    <Grid>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>{children}</Box>
      </Modal>
    </Grid>
  );
};

export default ModalDialog;
