import { createTheme } from "@mui/material/styles";
import { color_theme } from "./color-theme";

const theme = createTheme({
  palette: {
    primary: color_theme.text.black,
    success: color_theme.success,
    info: color_theme.info,
    error: color_theme.error,
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none",
        },
      },
    },
  },

  typography: {
    fontFamily: ['"Montserrat"'].join(","),
  },
});

export default theme;
