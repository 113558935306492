import { FC } from "react";

import { Box, Button, IconButton, Modal } from "@mui/material";

import { useShoppingCart } from "hooks";
import { useSelector } from "react-redux";
import { RootState } from "state";
import { formatCurrency } from "utils";
import { color_theme } from "../../color-theme";
import { images, texts } from "./constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "485px",
  bgcolor: "background.paper",
};

interface ProductInfoProps {
  open: boolean;
  handleClose: any;
}
const ProductPromo: FC<ProductInfoProps> = ({ open, handleClose }) => {
  const product = useSelector(
    (state: RootState) => state.shoppingCart.selected
  );
  const { addProduct } = useShoppingCart();
  const [name, flavor] = product.name?.split(" - ");

  const handleClick = () => {
    addProduct(product.id);
    handleClose();
  };

  return (
    <Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <IconButton sx={{ padding: 0 }} onClick={handleClose}>
              <img
                src={images.closeIcon}
                style={{
                  height: "17.5px",
                  objectFit: "cover",
                  marginRight: "32.25px",
                  marginTop: "22.25px",
                }}
                alt=""
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "42.25px",
              marginRight: "40px",
              marginLeft: "40px",
              height: "192px",
              width: "310px",
            }}
          >
            <img
              src={product.image_url}
              style={{
                height: "auto",
                objectFit: "cover",
              }}
              alt="imagem da promoção"
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                fontSize: 16,
                fontWeight: 600,
                lineHeight: "19.5px",
                mt: 3,
              }}
            >
              <div>{name}</div>
              <div>{flavor}</div>
              <div
                style={{
                  marginTop: 8,
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                {texts.promotionalPrice} {formatCurrency(product.price)}
              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              {/* adicionar as imagens aqui */}
              {/* {cards.map((card) => (
                <div key={card.value}>
                  <CardPropertiesProduct card={card} />
                </div>
              ))} */}
            </Box>

            <Button
              sx={{
                mt: 3,
                backgroundColor: color_theme.background.black.main,
                color: color_theme.text.white.main,
                textTransform: "lowercase",
                fontSize: 16,
              }}
              onClick={handleClick}
            >
              {texts.add}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default ProductPromo;
