import { createSlice } from "@reduxjs/toolkit";

interface Banner {
  id: number;
  image_url: string;
  category: string;
  subcategory: string;
}

interface InitialState {
  banners: Banner[];
  loading: boolean;
}

const initialState: InitialState = {
  banners: [],
  loading: true,
};

const bannersSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    setBanners: (state, action) => {
      return {
        ...state,
        banners: action.payload,
        loading: false,
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { actions, reducer } = bannersSlice;
