import { Box, Button, Typography } from "@mui/material";
import { ModalDialog } from "components";
import { FC } from "react";

interface ModalConfirmProps {
  open: boolean;
  setOpen(value: boolean): void;
}

const ModalConfirm: FC<ModalConfirmProps> = ({ open, setOpen }) => {
  const next = async () => {
    setOpen(false);
  };
  return (
    <ModalDialog open={open} setOpen={setOpen}>
      <Box justifyContent="center" alignItems="center" p={4}>
        <Typography fontWeight={600}>
          uma confirmação de exclusão de conta foi enviada para seu e-mail
        </Typography>
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={next}>
            OK
          </Button>
        </Box>
      </Box>
    </ModalDialog>
  );
};
export default ModalConfirm;
