import { Alert, Box, IconButton, Snackbar } from "@mui/material";
import { Layout, NavigationButtons } from "components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { endpoints } from "config";
import {
  usePaymentStatus,
  useProducts,
  useRedirectOnOrderStatusChange,
  useRequest,
} from "hooks";

import { useDispatch, useSelector } from "react-redux";
import { RootState, shoppingCartActions } from "../../state";
import { storeConfigActions } from "../../state/actions";
import { getStoreConfig } from "../../state/ducks/store-config";
import ModalConfirm from "./ModalConfirm";
import { images } from "./constants";

const CreditCardItem = ({ handleClick, handleDelete, last_digits }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        px: 6,
        py: 5,
        borderBottom: "1px solid black",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
        onClick={handleClick}
      >
        <IconButton
          sx={{
            alignSelf: "start",
            padding: "0",
            marginRight: "10px",
          }}
        >
          <img src={images.creditCard} alt="cartao de credito" />
        </IconButton>
        <Box>
          <Box
            sx={{
              fontWeight: "bold",
              marginBottom: "8px",
            }}
          >
            **** **** **** {last_digits}
          </Box>
          <Box
            sx={{
              fontWeight: "light",
              fontSize: "12px",
            }}
          >
            cartão de crédito
          </Box>
        </Box>
      </Box>
      <IconButton
        sx={{
          padding: "0",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleDelete}
      >
        <img src={images.closeIcon} alt="remover cartao de credito" />
      </IconButton>
    </Box>
  );
};

const PaymentType = () => {
  const navigate = useNavigate();
  usePaymentStatus();
  const [rejectedError, setRejectedError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [cards, setCards] = useState<any[]>([]);

  const { get, post, remove, loading, setLoading } = useRequest();

  const fetchCards = async () => {
    const response = await get(endpoints.cards);
    console.log("cards:", response);
    if (Array.isArray(response) && response?.length) {
      setCards(response);
    }
  };

  useEffect(() => {
    fetchCards();

    // eslint-disable-next-line
  }, []);

  const navigateWithCardData = (card: any) => {
    navigate("/add-credit-card", { state: { card } });
  };

  const deleteCard = async (card_id: number) => {
    const response = await remove(`${endpoints.cardDelete}/${card_id}`, null);
    if (!response.error) {
      setCards((old) => old.filter((card: any) => card.id !== card_id));
    } else {
      setRejectedError(
        "Algo deu errado, contate o suporte para mais informações!"
      );
    }
  };

  const dispatch = useDispatch();

  const cancelOrder = () => {
    dispatch(shoppingCartActions.setPixData(false));
    navigate("/order-confirmation");
  };

  useRedirectOnOrderStatusChange();

  const [modalOpen, setModalOpen] = useState(false);

  const activeOrder = useSelector(
    (state: RootState) => state.activeOrder.order
  );

  const confirmPaymentOnDelivery = async () => {
    const payment_data = {
      order_id: activeOrder?.id,
      payment_method: "ON_DELIVERY",
    };

    const response = await post(endpoints.chargePayment, payment_data);

    console.log("response", response);

    if (response?.status !== "FINISHED") {
      if (response.statusCode === 429) {
        setTimeout(
          () =>
            setRejectedError(
              "muitas tentativas de pagamento, tente novamente mais tarde."
            ),
          100
        );
      }

      setOpenSnackbar(true);

      throw new Error("falha na seleção de metodo de pagamento");
    }
  };

  const { storeIsOpen } = useProducts();

  const getStoreStatus = async () => {
    const res = await storeIsOpen();
    console.log("setting store config:", res);
    dispatch(storeConfigActions.setStoreConfig(res));
  };

  useEffect(() => {
    getStoreStatus();
    // eslint-disable-next-line
  }, []);

  const {
    credit_card_payment_available,
    pix_payment_available,
    on_delivery_payment_available,
  } = useSelector(getStoreConfig);

  return (
    <>
      <Layout
        notFullHeight
        style={{
          px: 0,
          py: 0,
        }}
      >
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          sx={{ width: "100%", mt: "120px" }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="error">
            {rejectedError}
          </Alert>
        </Snackbar>
        <Box sx={{ width: "100%" }}>
          {credit_card_payment_available ? (
            <>
              {cards &&
                cards?.map((card: any) => (
                  <CreditCardItem
                    {...card}
                    key={`${card.id}_${card.last_digits}`}
                    handleClick={() => navigateWithCardData(card)}
                    handleDelete={() => deleteCard(card.id)}
                  />
                ))}
            </>
          ) : (
            <></>
          )}

          {credit_card_payment_available ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                px: 6,
                py: 5,
                borderBottom: "1px solid black",
              }}
              onClick={() => navigate("/add-credit-card")}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <IconButton
                  sx={{
                    alignSelf: "start",
                    padding: "0",
                    marginRight: "10px",
                  }}
                >
                  <img src={images.creditCard} alt="cartao de credito" />
                </IconButton>
                <Box>
                  <Box
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "8px",
                    }}
                  >
                    cartão de crédito
                  </Box>
                  <Box
                    sx={{
                      fontWeight: "light",
                      fontSize: "12px",
                    }}
                  >
                    adicionar cartão de crédito
                  </Box>
                </Box>
              </Box>
              <Box>
                <img src={images.add} alt="adicionar cartao" />
              </Box>
            </Box>
          ) : (
            <></>
          )}

          {pix_payment_available ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                px: 6,
                py: 5,
                borderBottom: "1px solid black",
              }}
              onClick={() => navigate("/payment-pix")}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <IconButton
                  sx={{
                    alignSelf: "start",
                    padding: "0",
                    marginRight: "10px",
                  }}
                >
                  <img src={images.pix} alt="pix" />
                </IconButton>
                <Box>
                  <Box
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "8px",
                    }}
                  >
                    pix
                  </Box>
                  <Box
                    sx={{
                      fontWeight: "light",
                      fontSize: "12px",
                    }}
                  >
                    pague por pix
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <></>
          )}

          {on_delivery_payment_available ?? true ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                px: 6,
                py: 5,
                borderBottom: "1px solid black",
              }}
              onClick={() => setModalOpen(true)}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <IconButton
                  sx={{
                    alignSelf: "start",
                    padding: "0",
                    marginRight: "10px",
                  }}
                >
                  <img src={images.creditCard} alt="na entrega" />
                </IconButton>
                <Box>
                  <Box
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "8px",
                    }}
                  >
                    na entrega
                  </Box>
                  <Box
                    sx={{
                      fontWeight: "light",
                      fontSize: "12px",
                    }}
                  >
                    pagamento por cartão ou pix na entrega
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <ModalConfirm
          open={modalOpen}
          setOpen={setModalOpen}
          loading={loading}
          setLoading={setLoading}
          setError={setRejectedError}
          confirm={confirmPaymentOnDelivery}
        />
      </Layout>
      <NavigationButtons leftTitle="voltar" leftAction={cancelOrder} />
    </>
  );
};

export default PaymentType;
