import { Box } from "@mui/material";
import FumicoLoading from "components/FumicoLoading";

const LoadingOverlay = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 1,
        top: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          backgroundColor: "rgb(0,0,0,0.3)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FumicoLoading />
      </div>
    </Box>
  );
};

export default LoadingOverlay;
