import { differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { activePromoActions, promoActions, RootState } from "../../../state";
import { CountdownTimer, Header } from "../components";
import {
  Accent,
  Container,
  Content,
  Description,
  Image,
  PromoImagesContainer,
  Subtitle,
  Title,
} from "./styles";

import promoIgniteImage from "assets/promo/promo-ignite.png";
import promoNastyImage from "assets/promo/promo-nasty.svg";
import promoRelxImage from "assets/promo/promo-relx.svg";
import promoWakaImage from "assets/promo/promo-waka.png";
import { useNavigate } from "react-router-dom";
import { PROMO_WELCOME_ID } from "../../../state/ducks/promo";
import { useShoppingCart } from "hooks";

const PromoWelcome = () => {
  const [remainingSeconds, setRemainingSeconds] = useState<number>();

  const endDate = useSelector(
    (state: RootState) => state.promo.welcome.end_date
  );

  const { clearShoppingCart } = useShoppingCart();

  const calculateRemainingSeconds = () => {
    if (!endDate) return;

    let diff = differenceInSeconds(new Date(endDate), new Date());

    if (Number(remainingSeconds) <= 0) {
      dispatch(promoActions.restartWelcomePromo());

      let diff = differenceInSeconds(new Date(endDate), new Date());

      return diff;
    }

    setRemainingSeconds(diff > 0 ? diff : 0);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!endDate) dispatch(promoActions.startWelcomePromo());

    setTimeout(calculateRemainingSeconds, 1000);

    // eslint-disable-next-line
  }, [calculateRemainingSeconds]);

  const navigate = useNavigate();

  const viewPromo = (query: string) => {
    clearShoppingCart();
    dispatch(promoActions.setSelectedSubcategory(query));
    dispatch(activePromoActions.activatePromo(PROMO_WELCOME_ID));
    navigate(`/?promo=welcome&selected=${query}`);
  };

  return (
    <Container>
      <Header />
      <Content>
        <Title>BLACK FRIDAY</Title>
        <Description>você já conhece o RELX + ?</Description>
        <Description>
          se ainda não conhece, preparamos a melhor oportunidade exclusivamente
          para você:
        </Description>
        <Description>
          separamos 4 promoções imperdíveis abaixo! escolha o seu favorito e em
          30 minutos receba seu pedido no conforto da sua casa!
        </Description>
        <Description>*promo válida somente para primeiro pedido</Description>
        <Subtitle>
          promo válida por_<Accent>&lt;07 dias&gt;</Accent>
        </Subtitle>

        <CountdownTimer countdownSeconds={remainingSeconds} />

        <Subtitle>escolha um:</Subtitle>
        <PromoImagesContainer>
          <Image
            src={promoIgniteImage}
            alt="ignite"
            onClick={() => viewPromo("ignite")}
          />
          <Image
            src={promoRelxImage}
            alt="relx"
            onClick={() => viewPromo("relx")}
          />
          <Image
            src={promoNastyImage}
            alt="nasty"
            onClick={() => viewPromo("nasty")}
          />
          <Image
            src={promoWakaImage}
            alt="waka"
            onClick={() => viewPromo("waka")}
          />
        </PromoImagesContainer>
      </Content>
    </Container>
  );
};

export default PromoWelcome;
