import { apiErrorMessages, endpoints } from "config";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AddressesUserActions, addressListActions, RootState } from "state";
import useRequest from "./useRequest";

const useAddressList = (skipList: boolean = true) => {
  const dispatch = useDispatch();
  const { get, loading } = useRequest();
  const [errorMessage, setErrorMessage] = useState<undefined | string>();
  const [addresses, setAddresses] = useState<any>([]);

  const resultAddresses = useSelector((state: RootState) =>
    !skipList ? state.addressList.address : []
  );
  const favoriteAddresses = useSelector((state: RootState) =>
    !skipList ? state.addressesUser.addresses : []
  );

  const searchQuery = useSelector((state: RootState) =>
    !skipList ? state.addressList.searchQuery || "" : ""
  );

  const lastAddresses = useSelector(
    (state: RootState) => state.addressesUser.lastAddresses || []
  );

  const addressFormated = lastAddresses.map((address, index) => ({
    description: `${address.primary_text}, ${address.secondary_text}`,
    structured_formatting: {
      main_text: address?.primary_text,
      secondary_text: address?.secondary_text,
    },
    types: ["street_address", "geocode"],
    place_id: index,
  }));

  useEffect(() => {
    if (!skipList) return; // we don't have to load this everywhere
    setAddresses(favoriteAddresses);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (skipList) return; // we don't have to load this everywhere
    const filteredFavorites = favoriteAddresses.filter((address) => {
      const query =
        typeof searchQuery === "string" ? searchQuery.toLowerCase().trim() : "";
      const main = address.structured_formatting.main_text.toLowerCase();
      const secondary =
        address.structured_formatting.secondary_text.toLowerCase();

      return main.includes(query) || secondary.includes(query);
    });
    const filteredRecent = addressFormated.filter((address) => {
      const query =
        typeof searchQuery === "string" ? searchQuery.toLowerCase().trim() : "";

      const main = address.structured_formatting.main_text.toLowerCase();
      const secondary =
        address.structured_formatting.secondary_text.toLowerCase();
      return main.includes(query) || secondary.includes(query);
    });

    let completeAddressList = [
      ...filteredFavorites,
      ...filteredRecent,
      ...resultAddresses,
    ];

    // não duplica endereços quando há endereço favorito
    let uniqueAddresses = [];
    const addressIds: any = {};
    for (let address of completeAddressList) {
      if (addressIds[address.description]) continue;
      addressIds[address.description] = true;
      if (!address.types.includes("political")) {
        uniqueAddresses.push(address);
      }
    }
    setAddresses(uniqueAddresses);
    // eslint-disable-next-line
  }, [resultAddresses, searchQuery, favoriteAddresses]);

  const setAddressList = (
    address: google.maps.places.AutocompletePrediction[] | null
  ) => {
    if (address) {
      dispatch(addressListActions.setAddressList(address));
    }
  };

  const clearAddressList = () => {
    dispatch(addressListActions.setAddressList(favoriteAddresses));
  };

  const updateSearchQuery = (query: any) => {
    const text = typeof query === "object" ? query.address : query;
    dispatch(addressListActions.setSearchQuery(text));
  };

  const lastAdresses = async () => {
    setErrorMessage(undefined);

    const response = await get(endpoints.lastAddresses);
    if (
      !response.error &&
      ![500, 401].includes(response.statusCode) &&
      Array.isArray(response)
    ) {
      if (response) {
        dispatch(AddressesUserActions.setLastadresses(response));
      }
    } else {
      let message =
        apiErrorMessages[response?.message] || apiErrorMessages["default"];
      setErrorMessage(message);
    }

    return response;
  };

  return {
    setAddressList,
    clearAddressList,
    updateSearchQuery,
    addresses,
    lastAdresses,
    loading,
    errorMessage,
  };
};

export default useAddressList;
