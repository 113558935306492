import { useDispatch, useSelector } from "react-redux";
import { mapActions, RootState } from "state";
import { useNavigate } from "react-router-dom";

const useMap = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nextPath = useSelector((state: RootState) => state.map.nextPath);

  const goToMap = (path: string) => {
    console.log(path);
    dispatch(mapActions.setNextPath(path));
    navigate("/");
  };

  const getPath = () => {
    return nextPath;
  };

  const resetPath = () => {
    dispatch(mapActions.setNextPath(null));
  };

  const goNext = () => {
    if (nextPath) {
      navigate(nextPath);
      resetPath();
      return;
    }
    navigate("/catalog");
  };

  return { goToMap, getPath, resetPath, goNext };
};

export default useMap;
