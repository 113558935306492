import ".//style.css";

const ProgressBar = () => {
  return (
    <div className="progress">
      <div className="progress-bar"></div>
    </div>
  );
};

export default ProgressBar;
