import { ReactNode } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface LabelProps {
  children: ReactNode;
}

const Label = ({ children }: LabelProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        component="label"
        sx={{
          mb: "4px",
          fontSize: 16,
          fontWeight: 600,
          fontFamily: "Montserrat",
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default Label;
