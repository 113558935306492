export const texts = {
  recipientName: "quem vai receber o fumico?",
  recipientDescription: "como vamos encontrar você? (opcional)",
  login: "entrar",
  back: 'voltar',
  next: "avançar",
  descriptionPlaceholder: "cor da roupa, cabelo, local...",
  noAccount: "não tem uma conta?",
  noAccountLink: "faça o cadastro aqui",
};
