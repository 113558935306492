import { Box, IconButton, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDispatch, useSelector } from "react-redux";

import { HeaderBase } from "components";
import { GOOGLE_MAPS_API_KEY } from "config";
import { useAddressList, useGeocoder } from "hooks";
import { RootState, addressListActions } from "state";
import { color_theme } from "../../color-theme";
import { images, texts } from "./constants";

const { closeIcon } = images;

interface HeaderWithAutoCompleteProps {
  setOpenModal: any;
}
const HeaderWithAutoComplete: FC<HeaderWithAutoCompleteProps> = ({
  setOpenModal,
}) => {
  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
  });
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [latLng, setLatLng] = useState<google.maps.LatLng | null>();

  const { setAddressList, updateSearchQuery, clearAddressList } =
    useAddressList();

  const searchQuery = useSelector(
    (state: RootState) => state.addressList.searchQuery
  );

  const selectedAddress = useSelector(
    (state: RootState) => state.geolocation.selectedAddress
  );

  const { geocode } = useGeocoder();

  useEffect(() => {
    const updateLatLng = async () => {
      const coords = await geocode("Curitiba");
      const googleLatLng = new google.maps.LatLng(coords.lat, coords.lng);
      setLatLng(googleLatLng);
    };
    updateLatLng();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let text =
      selectedAddress?.structured_formatting?.main_text ||
      selectedAddress?.addressStreet;

    if (typeof text === "object") {
      // @ts-ignore
      text = text.address || text;
    }

    if (text) {
      const options: any = {
        input: text,
        componentRestrictions: { country: "br" },
        bounds: new google.maps.LatLngBounds(latLng),
      };

      setQuery(text);
      updateSearchQuery(text);

      if (latLng) {
        options["location"] = latLng;
      }

      getPlacePredictions(options);
    }

    // eslint-disable-next-line
  }, [selectedAddress]);

  useEffect(() => {
    if (placePredictions.length) {
      setAddressList(placePredictions || []);
    }

    // eslint-disable-next-line
  }, [placePredictions]);

  const openModal = () => {
    setOpenModal(true);
  };

  const _clearAddressList = () => {
    clearAddressList();
    dispatch(addressListActions.setSearchQuery(""));
    setQuery("");
  };

  const handleAddressChange = (evt: any) => {
    const query = evt.target.value;

    const options: any = {
      componentRestrictions: { country: "br" },
      input: query,
      bounds: new google.maps.LatLngBounds(latLng),
    };

    setQuery(query);
    getPlacePredictions(options);
    updateSearchQuery(query);

    if (evt.target.value === "") _clearAddressList();
  };

  useEffect(() => {
    if (query.trim() === "") {
      _clearAddressList();
    }

    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    setQuery(searchQuery);

    // eslint-disable-next-line
  }, []);

  return (
    <HeaderBase>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          width: "90%",
          backgroundColor: color_theme.background.black.light,
        }}
      >
        <TextField
          sx={{
            input: { color: color_theme.text.black.disabled },
            fontSize: 16,
            height: 40,
            width: "85%",
            fontWeight: 300,
            fontFamily: "Montserrat",
            justifyContent: "center",
          }}
          onClick={openModal}
          value={query}
          onChange={handleAddressChange}
          placeholder={texts.placeholder}
          fullWidth
        />
        {query && (
          <IconButton
            onClick={_clearAddressList}
            sx={{
              position: "absolute",
              right: "3%",
            }}
          >
            <img
              src={closeIcon}
              alt="close icon"
              style={{
                height: "10px",
                width: "10px",
              }}
            />
          </IconButton>
        )}
      </Box>
    </HeaderBase>
  );
};
export default HeaderWithAutoComplete;
