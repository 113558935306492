import { FC } from "react";

import { Typography } from "@mui/material";

interface BoxInfoProps {
  image: string;
  title: string;
  content: string | undefined;
}
const BoxInfo: FC<BoxInfoProps> = ({ image, title, content }) => {
  return (
    <>
      <Typography
        sx={{
          mt: 8,
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "20px",
        }}
      >
        <img
          style={{ marginRight: "8px" }}
          src={image}
          alt="endereço de entrega"
        />
        {title}
      </Typography>
      <Typography
        sx={{
          mt: 2,
          ml: 3,
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 300,
          fontSize: "16px",
          lineHeight: "20px",
        }}
      >
        {content}
      </Typography>
    </>
  );
};
export default BoxInfo;
