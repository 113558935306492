import { Box, MenuItem, Select, Typography } from "@mui/material";
import { useState } from "react";
import { color_theme } from "../../color-theme";
import { images, texts } from "./constants";

const countries = [
  {
    label: "Português Brasil",
    src: images.brasil,
    link: " ",
    value: "BR",
  },
];

const LanguageSelect = () => {
  const [country, setCountry] = useState(images.brasil);
  const [open, setOpen] = useState(false);

  const handleChange = (e: any) => {
    setCountry(e.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "17px",
          mb: 2,
          fontFamily: "Montserrat",
        }}
      >
        {texts.language}
      </Typography>
      <Select
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={country}
        name="country"
        onChange={handleChange}
        inputProps={{
          id: "open-select",
        }}
        sx={{
          paddingLeft: "0",
          borderBottom: "solid 1px #000",
          paddingBottom: "-8px",
          borderRadius: "0",
          zIndex: 5,
          "& .MuiInputBase-input": {
            padding: "0px 40px 10px 0px",
          },
          "& .MuiSelect-iconOutlined": {
            marginTop: "-6px",
            marginRight: "-13px",
            color: color_theme.text.black.main,
          },
          marginBottom: "100px",
        }}
      >
        {countries.map((option, key) => (
          <MenuItem value={option.src} key={key}>
            <div
              style={{
                display: "flex",
                // position: "relative",
              }}
            >
              <img src={option.src} alt={option.label} />{" "}
              <p style={{ margin: "0", paddingLeft: "16px" }}>{option.label}</p>
            </div>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default LanguageSelect;
