import { Box, Typography } from "@mui/material";
import { ScreenTitle } from "components";
import { useSelector, useDispatch } from "react-redux";
import { activeOrderActions, RootState } from "state";
import { images, texts } from "./constants";
import { useNavigate } from "react-router-dom";

const DeliveryInfo = () => {
  const recipientName = useSelector((state: RootState) => state.user.name);

  const { geolocation } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changeAddress = () => {
    dispatch(activeOrderActions.setIsOrderActive(false));
    navigate("/", { state: { nextRoute: "/order-confirmation" } });
  };

  return (
    <>
      <ScreenTitle>{texts.infoDeliveryTitle}</ScreenTitle>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          mt: 5,
          mb: 5,
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "17px",
            mb: 1,
            fontFamily: "Montserrat",
          }}
        >
          {texts.recipient}
        </Typography>
        <Typography
          sx={{ fontWeight: 300, fontSize: "16px", lineHeight: "20px" }}
        >
          {recipientName}
        </Typography>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "17px",
            mt: 3,
          }}
        >
          {texts.address}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontWeight: 300,
              fontSize: "16px",
              mt: 1,
              lineHeight: "20px",
            }}
          >
            {geolocation.selectedAddress.addressStreet}
          </Typography>
          <img
            src={images.pencil}
            onClick={changeAddress}
            alt="alterar endereço"
          />
        </Box>
      </Box>
    </>
  );
};
export default DeliveryInfo;
