import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import {
  ErrorMessage,
  Layout,
  NavigationButtons,
  NumberPhoneDDI,
  ScreenTitle,
  TextInput,
} from "components";
import { errorMessages } from "config";
import { useAuth } from "hooks";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, apiActions, userActions } from "state";
import { color_theme } from "../../color-theme";
import TermsOfUse from "./TermsOfuse";
import { texts } from "./constants";
import { isValid } from "utils/cpf";

const schema = yup
  .object({
    email: yup
      .string()
      .trim()
      .email(errorMessages.emailFormat)
      .required(errorMessages.emailRequired),
    phone: yup
      .string()
      .trim()
      .required(errorMessages.phoneRequired)
      .min(13, errorMessages.phoneMinLength)
      .nullable(),
    name: yup
      .string()
      .trim()
      .required(errorMessages.nameRequired)
      .min(4, errorMessages.nameMinLength),
    cpf: yup
      .string()
      .trim()
      .required(errorMessages.cpfRequired)
      .length(11, errorMessages.cpfLength)
      .test("cpf", errorMessages.cpfInvalid, (value) => isValid(value || "")),
    accepted_terms_of_use: yup
      .bool()
      .oneOf([true], errorMessages.termsOfUseRequired),
  })
  .required();

const useStyles = makeStyles({
  link: {
    marginLeft: 4,
    color: color_theme.text.black.main,
  },
});

const Signup = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { signup, loading } = useAuth();
  const recipientName = useSelector(
    (state: RootState) => state.shoppingCart.recipientName
  );
  const { code } = useParams();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: recipientName,
      phone: "",
      email: "",
      cpf: "",
      accepted_terms_of_use: false,
    },
  });

  const onSubmit = async (data: any) => {
    data.email = data.email.toLowerCase();
    if (code) data.invite = code;
    const res = await signup(data);
    if (res.error) setError(res.message);
    else {
      setError("");
      dispatch(userActions.setUser(data));
      dispatch(userActions.setNumberLastDigits(data.phone.slice(-4)));
      navigate("/confirmation-sms");
    }
  };

  const email = watch("email");

  useEffect(() => {
    if (email && error) setError("");
    // eslint-disable-next-line
  }, [email]);

  useEffect(() => {
    if (code) {
      const state = code.slice(0, 2);

      if (state.length === 2) dispatch(apiActions.setApiFromState(state));
    }
    // eslint-disable-next-line
  }, [code]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Layout>
        <Box
          sx={{
            mt: 1,
            maxHeight: 700,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
          }}
        >
          <Box width="100%">
            <ErrorMessage text={error} />

            <ScreenTitle>{texts.nameTitle}</ScreenTitle>
            <TextInput
              control={control}
              error={errors["name"]}
              name="name"
              autoComplete="name"
              placeholder={texts.namePlaceholder}
              required
            />

            <ScreenTitle>{texts.numberTitle}</ScreenTitle>
            <NumberPhoneDDI
              errors={errors["phone"]}
              control={control}
              placeholder={texts.numberPlaceholder}
            />

            <ScreenTitle>{texts.emailTitle}</ScreenTitle>
            <TextInput
              // {...register("email")}
              control={control}
              error={errors["email"]}
              name="email"
              autoComplete="email"
              placeholder={texts.emailPlaceholder}
              required
            />

            <ScreenTitle>{texts.cpfTitle}</ScreenTitle>
            <TextInput
              // {...register("cpf")}
              control={control}
              error={errors["cpf"]}
              name="cpf"
              autoComplete="cpf"
              placeholder={texts.cpfPlaceholder}
              mask={{
                format: "###.###.###-##",
              }}
              required
            />
            <TermsOfUse
              error={errors["accepted_terms_of_use"]}
              control={control}
            />
          </Box>

          <Box width="100%">
            <Grid container mb={5}>
              {texts.noAccount}
              <Link to="/login" className={classes.link}>
                {texts.noAccountLink}
              </Link>
            </Grid>
          </Box>
        </Box>
      </Layout>
      <NavigationButtons
        rightTitle={texts.signup}
        rightProps={{ type: "submit" }}
        rightLoading={loading}
      />
    </form>
  );
};

export default Signup;
