import { endpoints } from "config";
import useRequest from "./useRequest";

const useUser = () => {
  const { patch } = useRequest();

  const patchPhone = async (payload: any, id: string) => {
    //TODO tipar

    const res = await patch(`${endpoints.users}/${id}`, payload);
    return res;
  };
  return { patchPhone };
};

export default useUser;
