import { Box, IconButton, InputBase } from "@mui/material";
import { FC } from "react";
import { color_theme } from "../../color-theme";
import { images } from "./constants";

interface SearchProductsProps {
  setCategory: (value: string) => void;
  setSubCategory: (value: string) => void;
  setSearchProduct: (searchProduct: string) => void;
  setSearch: (value: string) => void;
  search: string;
}

const SearchProducts: FC<SearchProductsProps> = ({
  setSearch,
  search,
  setSearchProduct,
  setCategory,
  setSubCategory,
}) => {
  const cleanFIlterProducts = () => {
    setSearch("");
    setSearchProduct("");
  };

  const handleSearch = (event: any) => {
    event.preventDefault();
    setSearchProduct(search);
    setCategory("all");
    setSubCategory("all");
  };

  return (
    <form onSubmit={handleSearch}>
      <Box
        sx={{
          margin: "40px 0 24px 30px",
          width: "85%",
          height: "43px",
          borderWidth: "2px",
          borderColor: color_theme.background.black.main,
          borderStyle: "solid",
          display: "flex",
          flexDirection: "row",
          borderRadius: "4px",
        }}
      >
        <InputBase
          type="text"
          sx={{
            ml: 1,
            flex: 1,
            borderRadius: "2px",
            display: "flex",
          }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          value={search}
          placeholder="pesquise seu produto"
        />
        {search && (
          <IconButton
            onClick={cleanFIlterProducts}
            sx={{
              marginRight: "10px",
              alignSelf: "center",
              height: "12px",
              width: "12px",
            }}
          >
            <img src={images.closeIcone} alt="close icon" />
          </IconButton>
        )}
        <IconButton
          type="button"
          sx={{
            width: "32px",
            height: "40px",
            backgroundColor: color_theme.background.black.main,
            border: "none",
            borderRadius: "0",
          }}
          onClick={handleSearch}
        >
          <img
            style={{
              height: "14.29px",
              objectFit: "contain",
            }}
            src={images.magnifyingGlass}
            alt="lupa"
          />
        </IconButton>
      </Box>
    </form>
  );
};
export default SearchProducts;
