import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useOrderStatus from "./useOrderStatus";

const useRedirectOnOrderStatusChange = () => {
  const { orderStatus } = useOrderStatus();
  const navigate = useNavigate();

  useEffect(() => {
    const activeStatus = ["PENDING", "APPROVED", "DELIVERING", "FINISHED"];
    if (activeStatus.includes(orderStatus)) {
      navigate("/order-confirmation-status");
    }
    // eslint-disable-next-line
  }, [orderStatus]);
};

export default useRedirectOnOrderStatusChange;
