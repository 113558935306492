import { useState } from "react";
import { useDispatch } from "react-redux";

import { apiErrorMessages, endpoints } from "config";
import { bannersActions } from "state";

import useRequest from "./useRequest";

const useBanners = () => {
  const [errorMessage, setErrorMessage] = useState<undefined | string>();
  const { get } = useRequest();
  const dispatch = useDispatch();

  const getBanners = async () => {
    setErrorMessage(undefined);

    bannersActions.setLoading(true);

    const response = await get(endpoints.banners);

    bannersActions.setLoading(false);

    if (!response.error) {
      if (response) {
        console.log(response, "res");
        dispatch(bannersActions.setBanners(response));
      }
    } else {
      let message =
        apiErrorMessages[response?.message] || apiErrorMessages["default"];
      setErrorMessage(message);
    }
    return response;
  };

  return { getBanners, errorMessage };
};

export default useBanners;
