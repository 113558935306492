import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { FC } from "react";
import { color_theme } from "../../color-theme";

interface SnackBarProps {
  text: string;
  event?: any;
  removeArrows?: boolean;
}

const SnackBar: FC<SnackBarProps> = ({ text, event, removeArrows }) => {
  return (
    <motion.div
      animate={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: -20 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          minHeight: 56,
          fontSize: 16,
          fontWeight: 600,
          color: color_theme.text.white.main,
          lineHeight: "19.5px",
          fontFamily: "Montserrat",
          backgroundColor: color_theme.background.black.light,
        }}
        onClick={event}
      >
        {text}
        {!removeArrows && <ArrowRightAltIcon sx={{ ml: 1 }} />}
      </Box>
    </motion.div>
  );
};

export default SnackBar;
