import { Box, CircularProgress } from "@mui/material";
import { color_theme } from "../../color-theme";

interface ModalCardVerificationProps {
  isActive: boolean;
}

const ModalCardVerification = ({ isActive }: ModalCardVerificationProps) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "120px",
        left: 0,
        height: "calc(100vh - 120px)",
        pt: "250px",
        background: color_theme.background.white.main,
        width: "100%",
        zIndex: "2",
        display: isActive ? "block" : "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "200px",
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: "Montserrat",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            fontWeight: "bold",
          }}
        >
          aguardando pagamento
        </Box>
        <Box>
          <CircularProgress size={"52px"} />
        </Box>
        <Box
          sx={{
            fontSize: "12px",
            width: "65%",
          }}
        >
          estamos aguardando seu pagamento, logo será confirmado.
        </Box>
      </Box>
    </Box>
  );
};

export default ModalCardVerification;
