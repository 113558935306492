import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useShoppingCart } from "hooks";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "state";
import { formatCurrency } from "utils";
import { color_theme } from "../../color-theme";
import { images } from "./constants";

interface ShoppingCartProductProps {
  product: ProductType;
}
const ShoppingCartProduct: FC<ShoppingCartProductProps> = ({ product }) => {
  const [name, flavor] = product.name?.split(" - ");
  const { setQuantity } = useShoppingCart();

  const handleChange = () => {
    setQuantity(product.id, 0);
  };

  return (
    <Box
      key={product.id}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        color: color_theme.text.white.main,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: 2,
          mb: 2,
          ml: 3,
        }}
      >
        <Box sx={{ fontSize: 16, mr: 2, fontWeight: 600 }}>
          {product.quantity}
        </Box>
        <Box>
          <Box sx={{ fontSize: 16, lineHeight: "19.5px", fontWeight: 600 }}>
            {name}
          </Box>
          <Box sx={{ fontSize: 16, lineHeight: "19.5px", fontWeight: 600 }}>
            {flavor}
          </Box>
          <Box
            sx={{
              fontSize: 10,
              marginTop: 1,
              display: "flex",
            }}
          >
            <Box
              sx={{
                fontWeight: 400,
                mr: 2,
                color: color_theme.text.white.main,
              }}
            >
              Valor unitário {formatCurrency(product.price, "")}
            </Box>
            <Box sx={{ fontWeight: 700 }}>
              Valor total{" "}
              {product.subtotal_integral !== product.subtotal && (
                <span
                  style={{ fontWeight: 400, textDecoration: "line-through" }}
                >
                  {formatCurrency(product.subtotal_integral, "")}
                </span>
              )}{" "}
              <span>{formatCurrency(product.subtotal, "")}</span>
            </Box>
          </Box>
        </Box>
      </Box>
      <IconButton
        sx={{
          padding: 0,
          mr: 2,
        }}
        onClick={handleChange}
      >
        <img
          src={images.deleteIcon}
          style={{
            height: "32px",
            objectFit: "cover",
          }}
          alt="remover produto"
        />
      </IconButton>
    </Box>
  );
};

const ShoppingCart = () => {
  const [expanded, setExpanded] = useState(false);
  const products = useSelector(
    (state: RootState) => state.shoppingCart.products
  );
  const { subtotal_integral, subtotal } = useSelector(
    (state: RootState) => state.shoppingCart
  );
  const { getTotalPrice } = useShoppingCart();

  const toggleShoppingCartDetails = () => setExpanded(!expanded);

  return products?.length ? (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        bottom: "80px",
        zIndex: 99,
      }}
    >
      {expanded && (
        <Box
          sx={{
            flexDirection: "column",
            backgroundColor: "#595959",
            overflowY: "auto",
            height: "100%",
            maxHeight: "282px",
          }}
        >
          {products.map((item: ProductType) => (
            <ShoppingCartProduct product={item} key={item.id} />
          ))}
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#232323",
          minHeight: 56,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            marginLeft: "24px",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={toggleShoppingCartDetails}
        >
          <Box
            sx={{
              fontSize: 16,
              fontWeight: 400,
              color: color_theme.text.white.main,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", fontWeight: 600 }}
            >
              <p>
                total:{" "}
                {subtotal_integral !== subtotal && (
                  <span
                    style={{ fontWeight: 400, textDecoration: "line-through" }}
                  >
                    {formatCurrency(subtotal_integral, "")}
                  </span>
                )}{" "}
                <span>{formatCurrency(getTotalPrice(), "")}</span>
              </p>
            </div>
          </Box>
          <Box>
            <IconButton>
              {expanded ? (
                <ArrowDropDown sx={{ color: "#ABABAB", fontSize: 32 }} />
              ) : (
                <ArrowDropUp sx={{ color: "#ABABAB", fontSize: 32 }} />
              )}
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default ShoppingCart;
