import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  address: google.maps.places.AutocompletePrediction[];
  searchQuery: "";
}

const initialState: InitialState = {
  address: [],
  searchQuery: "",
};

const addressListSlice = createSlice({
  name: "addressList",
  initialState,
  reducers: {
    setAddressList: (state, action) => {
      state.address = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
});

export const { actions, reducer } = addressListSlice;
