import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";

import { endpoints } from "config";
import { useNavigate } from "react-router-dom";
import { activeOrderActions, RootState, shoppingCartActions } from "state";

const usePaymentStatus = () => {
  const dispatch = useDispatch();

  const { id: paymentId, status } = useSelector(
    (state: RootState) => state.shoppingCart.pixData
  );

  const navigate = useNavigate();
  const HOST = useSelector((state: RootState) => state.api.host);

  useEffect(() => {
    console.log("payment_id atualizado...", { paymentId });

    if (!paymentId) {
      console.log("[websocket: payment-status] payment id nao encontrado...");
      return;
    }

    const socket = io(HOST.concat(endpoints.paymentStatusWebsocket), {
      query: { payment: paymentId },
    });

    socket.on("connect", () => {
      console.log("[payment-status] connected...");
    });

    socket.on("disconnect", () => {
      console.log("[payment-status] disconnected...");
    });

    socket.on("payment-status", async (payload) => {
      // todo: udpate current order
      console.log("received payment-status update:", payload);

      console.log("PAYLOAD STATUS", payload.status);
      if (payload.status === "paid" && payload.status !== status) {
        console.log("status do pagamento atualizado para aprovado...");
        console.log(
          "redirecionando para acompanhamento de status do pedido..."
        );

        dispatch(activeOrderActions.setStatus("PENDING"));
        dispatch(activeOrderActions.setIsOrderActive(false));
        dispatch(shoppingCartActions.cleanShoppingCart());

        navigate("/order-confirmation-status");
      }

      dispatch(
        shoppingCartActions.setPixData({
          status: payload.status,
        })
      );
    });

    return () => {
      socket.close();
    };

    // eslint-disable-next-line
  }, [paymentId]);

  return { status };
};

export default usePaymentStatus;
