import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  order?: OrderType;
  isOrderActive?: boolean;
}

const initialState: InitialState = {
  order: undefined,
};

const activeOrderSlice = createSlice({
  name: "activeOrder",
  initialState,
  reducers: {
    setOrder: (state, action) => ({ ...state, order: action.payload }),
    setIsOrderActive: (state, action) => {
      state.isOrderActive = action.payload;
    },
    setStatus: (state, action) => {
      if (state.order) state.order.status = action.payload;
    },
  },
});

export const { actions, reducer } = activeOrderSlice;
