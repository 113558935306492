import all from "assets/all.png";
import allSelected from "assets/allSelected.png";
import ignite from "assets/ignite.png";
import igniteSelected from "assets/igniteSelected.png";
import sheep from "assets/sheep.png";
import sheepSelected from "assets/sheepSelected.png";
import waka from "assets/waka.svg";
import wakaSelected from "assets/wakaSelected.svg";

export const images = {
  all,
  allSelected,
  ignite,
  igniteSelected,
  sheep,
  sheepSelected,
  waka,
  wakaSelected,
};

export const brands = [
  { relx: ["pod, aparelhos"] },
  { fumicos: ["the black sheep", "ignite", "waka", "concept"] },
  { recarregáveis: ["resistências, nasty, BLVK"] },
  { headShop: ["sedas", "filtros", "trituradores", "isqueiros", "tabaco"] },
  { promoções: ["promo week", "combos"] },
];
