import { Box, Button, CircularProgress, Container } from "@mui/material";
import { useProducts } from "hooks";
import { FC, useMemo } from "react";
import { images } from "./constants";

interface CatalogFilterProps {
  selected: string;
  setSelected: any;
  categorySelected: string;
  setCategorySelected: any;
  categories: any[];
  hideSelectAll?: boolean;
}

const CatalogFilter: FC<CatalogFilterProps> = ({
  selected,
  setSelected,
  categorySelected,
  categories,
  hideSelectAll,
}) => {
  // const categories = useSelector(
  //   (state: RootState) => state.products.categories
  // );
  const { loading } = useProducts();

  const subCategories = useMemo<SubCategoriesType[]>(() => {
    let filtered: CategoriesType[];

    if (categorySelected === "all") {
      filtered = categories;
    } else {
      filtered = categories.filter(
        (data: CategoriesType) => data.name === categorySelected
      );
    }

    return filtered
      .map((data: CategoriesType) =>
        data.subcategories.filter((s: SubCategoriesType) => s.name)
      )
      .flat();
  }, [categories, categorySelected]);

  return (
    <Container
      component="main"
      sx={{
        width: "100vw",
        p: 0,
        m: 0,
        overflowX: "scroll",
        "::-webkit-scrollbar": {
          display: "none",
        },
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          mb: 3,
          display: "flex",
          flexDirection: "row",
          pl: "32px",
          pr: "24px",
          justifyContent: "flex-start",
        }}
      >
        {!hideSelectAll && (
          <Button
            sx={{
              p: 0,
              margin: 0,
              marginRight: "8px",
              height: "53px",
              width: "72px",
              maxWidth: "72px",
              minWidth: "72px",
              border: "2px solid black",
              borderRadius: "6px",
            }}
            onClick={() => setSelected("all")}
          >
            <img
              src={images.allSelected}
              style={{
                height: "53px",
                minHeight: "53px",
                width: "100%",
                objectFit: "contain",
                filter: `invert(${selected === "all" ? "0" : "1"})`,
              }}
              alt=""
            />
          </Button>
        )}
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              paddingBottom: "20%",
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        )}
        {subCategories.map((value: SubCategoriesType) => (
          <Button
            key={value.name}
            sx={{
              p: 0,
              margin: 0,
              marginRight: "8px",
              height: "53px",
              width: "72px",
              maxWidth: "72px",
              minWidth: "72px",
              border: "2px solid black",
              borderRadius: "6px",
              overflow: "hidden",
            }}
            onClick={() => setSelected(value.name)}
          >
            <img
              alt={value.name}
              src={
                selected === value.name
                  ? value.image_src_selected
                  : value.image_src
              }
              style={{
                height: "53px",
                minHeight: "53px",
              }}
            />
          </Button>
        ))}
      </Box>
    </Container>
  );
};
export default CatalogFilter;
