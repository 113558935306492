import { Box, Typography } from "@mui/material";
import { color_theme } from "../../color-theme";
import { images, texts } from "./constants";

const AddressFavorites = ({ addressesFavorites, handleClick }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "70px",
        width: "100%",
        backgroundColor: color_theme.background.black.light,
        overflowX: "scroll",
        overflowY: "hidden",
      }}
    >
      <img
        src={images.starWhite}
        alt="Endereços favoritos"
        style={{
          height: "21px",
          width: "21px",
          margin: "0 16px",
        }}
      />
      {addressesFavorites.length ? (
        addressesFavorites.map((address: any) => (
          <Box
            key={address.id}
            sx={{
              p: "3px 12px",
              mr: 2,
              minWidth: "120px",
              height: "25px",
              border: "2px solid #F0F0F0",
              borderRadius: "15px",
              backgroundColor: color_theme.background.white.main,
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "15px",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
            }}
            onClick={() => handleClick(address)}
          >
            {address.structured_formatting.main_text}
          </Box>
        ))
      ) : (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "20px",
            color: "#626161",
          }}
        >
          {texts.noAddressesFavorites}
        </Typography>
      )}
    </Box>
  );
};

export default AddressFavorites;
