import { FC } from "react";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { color_theme } from "../../color-theme";

const ArrowLeftAltIcon = (props: any) => {
  return <ArrowRightAltIcon style={{ transform: "scale(-1)" }} {...props} />;
};

interface NavigationButtonProps {
  leftTitle?: string;
  leftAction?: any;
  leftProps?: any;
  leftDisabled?: boolean;

  rightTitle?: string;
  rightAction?: any;
  rightProps?: any;
  rightDisabled?: boolean;
  rightLoading?: boolean;
  style?: any;
  removeArrows?: boolean;
}

const NavigationButtons: FC<NavigationButtonProps> = ({
  leftTitle,
  leftAction,
  leftProps,
  leftDisabled,
  rightTitle,
  rightAction,
  rightProps,
  rightDisabled,
  rightLoading,
  removeArrows,
  style,
}) => {
  const double = leftTitle && rightTitle;
  const flex = double ? 6 : 12;
  const padding = double ? "1px" : 0;

  return (
    <div
      style={{
        backgroundColor: color_theme.background.white.main,
        zIndex: 1301,
      }}
    >
      <Grid container sx={{ width: "100%", height: 80, zIndex: 1301 }}>
        {leftTitle && (
          <Grid item xs={flex} sx={{ paddingRight: padding }}>
            <Button
              variant="contained"
              fullWidth
              onClick={leftAction}
              sx={{
                height: 80,
                fontSize: 16,
                backgroundColor: color_theme.button.left.background,
              }}
              {...leftProps}
              disabled={leftDisabled}
              style={style}
            >
              {!removeArrows && <ArrowLeftAltIcon sx={{ ml: 1 }} />}
              <div
                style={{
                  margin: "0 8px",
                  fontFamily: "Courier Prime",
                  textTransform: "lowercase",
                  fontWeight: "700",
                  fontSize: "22px",
                  lineHeight: "25px",
                  letterSpacing: "-0.075em",
                }}
              >
                {leftTitle}
              </div>
            </Button>
          </Grid>
        )}
        {rightTitle && (
          <Grid item xs={flex} sx={{ paddingLeft: padding }}>
            <Button
              variant="contained"
              fullWidth
              onClick={rightAction}
              {...rightProps}
              sx={{ height: 80, fontSize: 16 }}
              disabled={rightDisabled || rightLoading}
            >
              <div
                style={{
                  margin: "0 8px",
                  fontFamily: "Courier Prime",
                  textTransform: "lowercase",
                  fontWeight: "700",
                  fontSize: "22px",
                  lineHeight: "25px",
                  letterSpacing: "-0.075em",
                }}
              >
                {rightLoading ? (
                  <CircularProgress
                    color="inherit"
                    sx={{ position: "relative", top: 3 }}
                  />
                ) : (
                  rightTitle
                )}
              </div>
              {!removeArrows && !rightLoading && (
                <ArrowRightAltIcon sx={{ ml: 1 }} />
              )}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default NavigationButtons;
