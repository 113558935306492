import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Header } from "components";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "state";

interface LayoutProps {
  // showShoppingCart?: boolean;
  style?: any;
  notFullHeight?: boolean;
}

const Layout: FC<LayoutProps> = ({ children, style, notFullHeight }) => {
  const menuOpen = useSelector((state: RootState) => state.menu.open);

  return (
    <>
      <Header />
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          pt: 5,
          pb: 5,
          px: 4,
          ...style,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflowY: menuOpen ? "hidden" : "auto",
          overflowX: "hidden",
          ...style,
        }}
      >
        <Box width="100%" sx={{ minHeight: "100%", display: "flex" }}>
          <Box
            width="100%"
            sx={{
              mt: style?.margin ?? 1,
              maxHeight: 700,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              minHeight: notFullHeight ? "auto" : "100%",
            }}
          >
            {children}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Layout;
