import { FC } from "react";
import { color_theme } from "../../color-theme";

interface Props {
  text?: string;
}

const ErrorMessage: FC<Props> = ({ text }) => {
  const isArray = Array.isArray(text);
  const data = isArray ? text : [text];

  return text ? (
    <>
      {data.map((message, index) => (
        <p
          key={index}
          style={{
            color: color_theme.error.main,
            marginTop: 0,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {message}
        </p>
      ))}
    </>
  ) : (
    <></>
  );
};

export default ErrorMessage;
