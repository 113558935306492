import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface InitialState {
  store_is_open: boolean;
  credit_card_payment_available: boolean;
  pix_payment_available: boolean;
  auto_register?: boolean;
  min_order_to_invite?: number;
  on_delivery_payment_available?: boolean;
}

const initialState: InitialState = {
  store_is_open: true,
  credit_card_payment_available: false,
  pix_payment_available: false,
  on_delivery_payment_available: true,
};

const storeConfigSlice = createSlice({
  name: "storeConfig",
  initialState,
  reducers: {
    setStoreConfig: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const getStoreConfig = (state: RootState) => state.storeConfig;

export const { actions, reducer } = storeConfigSlice;
