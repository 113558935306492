import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { CounterInput } from "components";
import { useSelector } from "react-redux";
import { formatCurrency } from "utils";
import { color_theme } from "../../color-theme";
import { RootState } from "../../state";

interface ModalDetailsProductProps {
  setOpen: any;
}

const flex_map = ["flex-start", "center", "flex-end"];

const ModalDetailsProduct = (props: ProductType & ModalDetailsProductProps) => {
  const { setOpen, ...product } = props;
  product.product_details = [
    ...(product?.product_details || []),
    ...new Array(3 - (product?.product_details?.length || 0)).fill(""), // pra ter 3 espaços
  ];
  const [name, flavor] = product.name.split(" - ");

  const handleClose = () => {
    setOpen(false);
  };

  const isWelcomePromoActive = useSelector(
    (state: RootState) => state.activePromo.active
  );

  const blockAddProduct = isWelcomePromoActive && product?.quantity > 0;

  let disabled = !product.has_stock;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "21px",
          mr: "35px",
          ml: 5,
          mb: "29px",
          outline: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            mb: "42px",
          }}
        >
          <CloseIcon onClick={handleClose} />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <img
            src={product.image_url}
            style={{
              height: "176px",
              width: "127px",
              objectFit: "cover",
            }}
            alt="Imagem ilustrativa do fumico"
          />
          <Box sx={{ display: "flex", height: "150px" }}>
            <CounterInput
              id={product.id}
              quantity={product.quantity}
              disableAdd={blockAddProduct}
              disabled={disabled}
              clearWhenAdding={isWelcomePromoActive}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            fontSize: 16,
            fontWeight: 700,
            mb: 1,
          }}
        >
          <div>{name}</div>
          <div>{flavor}</div>
        </Box>
        <Box
          sx={{
            fontSize: 16,
            mb: 2,
            fontWeight: 400,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p
            style={{
              marginRight: "8px",
            }}
          >
            preço:
          </p>
          {product.price !== product.standard_price &&
            product.standard_price !== null && (
              <p
                style={{
                  color: color_theme.text.black.disabled,
                  fontWeight: 300,
                  textDecoration: "line-through",
                  marginRight: "8px",
                }}
              >
                {formatCurrency(product.standard_price)}
              </p>
            )}
          {formatCurrency(product.price)}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-around",
            justifyContent: "flex-start",
            maxWidth: "270px",
          }}
        >
          {product.product_details?.map(
            (details_image: string, index: number) => (
              <div
                key={index}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: flex_map[index],
                }}
              >
                {details_image && (
                  <img
                    style={{
                      display: "block",
                      maxWidth: "82px",
                      maxHeight: "53px",
                      width: "82px",
                      height: "53px",
                    }}
                    src={details_image}
                    alt=""
                  />
                )}
              </div>
            )
          )}
        </Box>
      </Box>
    </>
  );
};
export default ModalDetailsProduct;
