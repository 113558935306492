import { createContext, FC, useContext, useEffect } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { UseLoadScriptOptions } from "@react-google-maps/api/src/useJsApiLoader";
import { useDispatch } from "react-redux";
import { mapActions } from "state";

export type GoogleMapsState = {
  isLoaded: boolean;
  loadError?: Error;
};

const GoogleMapsContext = createContext<GoogleMapsState>({ isLoaded: false });

export const GoogleMapsProvider: FC<UseLoadScriptOptions> = ({
  children,
  ...loadScriptOptions
}) => {
  const { isLoaded, loadError } = useJsApiLoader(loadScriptOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mapActions.setLoading(isLoaded));
    // eslint-disable-next-line
  }, [isLoaded]);

  return (
    <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);
