import { useState, useEffect, useMemo, useRef } from "react";

import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Layout, NavigationButtons, TextInput } from "components";
import { activeChatActions, RootState } from "state";
import { useChat } from "hooks";

import { images, texts } from "./constants";
import "./styles.css";

const OrderChat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [textMessage, setTextMessage] = useState("");
  const raw_messages = useSelector(
    (state: RootState) => state.activeChat.messages
  );
  const messages = useMemo(() => {
    return raw_messages.map((message, index) => {
      if (
        raw_messages.length > index + 1 &&
        raw_messages[index + 1].user.id === message.user.id
      ) {
        return { ...message, show_tip: false };
      } else {
        return { ...message, show_tip: true };
      }
    });
    // eslint-disable-next-line
  }, [raw_messages]);

  const { listMessages, createMessage } = useChat(true);
  const userId = useSelector((state: RootState) =>
    parseInt(state.user.id || "-1")
  );

  useEffect(() => {
    listMessages();
    // eslint-disable-next-line
  }, []);

  const back = () => {
    navigate("/order-confirmation-status");
  };

  const onSubmit = () => {
    if (textMessage !== "") {
      const message = {
        id: Date.now(),
        user: { id: userId },
        created_at: new Date().toISOString(),
        message: textMessage,
      };

      dispatch(activeChatActions.appendMessage(message));
      createMessage(message.message);
      setTextMessage("");
    }
  };

  const chatLastMessageRef = useRef<any>(null);

  useEffect(() => {
    if (chatLastMessageRef?.current) {
      chatLastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <>
      <Layout>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {(messages || []).map((message) => (
            <div key={message.id} style={{ width: "100%" }}>
              {message.user.id !== userId ? (
                <div
                  className={`talk-bubble-left tri-left btm-left ${
                    message.show_tip ? "show-tip" : ""
                  }`}
                >
                  <div className="talktext-left">
                    <p className="message">{message.message}</p>
                  </div>
                </div>
              ) : (
                <div
                  className={`talk-bubble-right tri-right btm-right ${
                    message.show_tip ? "show-tip" : ""
                  }`}
                >
                  <div
                    className={`triangle ${message.show_tip ? "show-tip" : ""}`}
                  />
                  <div className="talktext-right">
                    <p className="message">{message.message}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div ref={chatLastMessageRef} />
        </Box>
      </Layout>

      <Box
        sx={{
          px: 3,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <TextInput
          value={textMessage}
          placeholder={texts.message}
          onChange={(e: any) => setTextMessage(e.target.value)}
          onKeyDown={(e: any) => e.key === "Enter" && onSubmit()}
        />
        <img
          alt="Enviar mensagem"
          style={{ marginLeft: "19px", marginTop: "8px" }}
          src={images.ArrowRightBlack}
          onClick={onSubmit}
        />
      </Box>
      <NavigationButtons leftTitle={texts.back} leftAction={back} />
    </>
  );
};
export default OrderChat;
