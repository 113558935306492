import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  orders: OrderType[];
}

const initialState: InitialState = {
  orders: [],
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrders: (_, action) => {
      return { orders: action.payload };
    },
    addOrder: (state, action) => {
      return { orders: [action.payload, ...(state?.orders || [])] };
    },
    resetOrders: () => {
      return { orders: [] };
    },
  },
});

export const { actions, reducer } = ordersSlice;
