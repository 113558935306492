import styled from "styled-components";

export const Container = styled.div`
  /* min-height: 100%; */
  background-color: #000;
  color: #fff;
  font-family: "Courier Prime", Courier, monospace;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  /* min-height: 100%; */
  background-color: #000;
  display: flex;
  flex-direction: column;

  padding: 64px 32px;
`;

export const Title = styled.h1`
  font-size: 24px;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 0;
`;

export const Description = styled.p`
  margin: 0;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  margin: 8px 0px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin-top: 24px;
`;

export const Accent = styled.span`
  color: #0ac499;
`;

export const PromoImagesContainer = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 144px 144px;
  justify-content: space-around;
  column-gap: 8px;
  row-gap: 32px;
`;

export const Image = styled.img`
  border-radius: 15px;

  :active {
    background-color: rgba(255, 255, 255, 0.2);
    outline: 4px solid #fff;
  }
`;
