import { Box, Typography } from "@mui/material";
import { Layout, NavigationButtons } from "components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "state";
import { images, texts } from "./constants";
import LanguageSelect from "./LanguageSelect";

const UserProfile = () => {
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);

  function formataCPF(cpf: any) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  function formataNumber(number: any) {
    return number.replace(/(\d{2})(\d{2})(\d{5})(\d{3})/, "$1 $2 $3-$4");
  }

  return (
    <>
      <Layout>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mb: 2,
            }}
          >
            <img src={images.perfil} alt="perfil" />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "20px",
                marginLeft: 1,
              }}
            >
              {user.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <img src={images.cpf} alt="cpf" />
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "16px",
                lineHeight: "20px",
                marginLeft: 1,
              }}
            >
              {formataCPF(user.cpf)}
            </Typography>
          </Box>

          <Box sx={{ mt: 7 }}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "17px",
                mb: 2,
                fontFamily: "Montserrat",
              }}
            >
              {texts.email}
            </Typography>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "16px",
                lineHeight: "20px",
                mb: 3,
              }}
            >
              {user.email}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "17px",
                mb: 2,
                fontFamily: "Montserrat",
              }}
            >
              {texts.phone}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", mb: 7 }}>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: "16px",
                  lineHeight: "20px",
                  marginRight: "80px",
                }}
              >
                {formataNumber(user.phone)}
              </Typography>
              <img
                src={images.pen}
                alt="edit"
                onClick={() => navigate("/user/updateuserphone")}
              />
            </Box>
          </Box>
          <LanguageSelect />
        </Box>
      </Layout>
      <NavigationButtons
        leftTitle={texts.back}
        leftAction={() => navigate("/")}
      />
    </>
  );
};

export default UserProfile;
