import { Typography } from "@mui/material";
import { images } from "./constants";

const EmptyStore = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center ",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "18px",
          lineHeight: "22px",
          textAlign: "center",
          margin: "0",
          padding: "0",
          marginBottom: "16px",
        }}
        variant="h3"
        component="h3"
      >
        infelizmente no momento estamos fechados :(
      </Typography>
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "300",
          fontSize: "14px",
          lineHeight: "17px",
          textAlign: "center",
          marginBottom: "40px",
        }}
        component="p"
      >
        horário de funcionamento das 08h às 23:59h
      </Typography>
      <img
        style={{ width: "148px", height: "124px", opacity: "0.3" }}
        src={images.badSmile}
        alt="badSmile"
      ></img>
    </div>
  );
};

export default EmptyStore;
