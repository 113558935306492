import marker from "assets/marker.svg";
import chatMessage from "assets/chatMessage.svg";
import chatMessageDisable from "assets/chatMessageDisable.svg";
import clock from "assets/clock.svg";
import closeIcon from "assets/closeIcon.svg";
export const texts = {
  address: "endereço",
  delivery: "previsão de entrega",
  description:
    "Seu pedido foi confirmado, logo entraremos em contato com você.",
  next: "confirmar entrega",
};

export const images = {
  marker,
  clock,
  chatMessage,
  chatMessageDisable,
  closeIcon,
};
