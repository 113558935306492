export const formatCurrency = (value: any, separator: string = " ") => {
  if (typeof value !== "number") value = 0;
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  })
    .format(value)
    .replace(/\s/g, separator);
};

export const formatDate = (value: any) => {
  if (typeof value !== "string") return "";
  const date = new Date(value);
  return date.toLocaleDateString();
};

export const formatHour = (value: any) => {
  if (typeof value !== "string") return "";
  const hours = new Date(value).getHours();
  const minutes = new Date(value).getMinutes();
  return `${hours > 9 ? "" : "0"}${hours}h${minutes > 9 ? "" : "0"}${minutes}`;
};
