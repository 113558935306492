import { Box, Button, Typography } from "@mui/material";
import { ModalDialog } from "components";
import { useEffect } from "react";
import { texts } from "../Menu/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../state";

interface IProps {
  open: boolean;
  openModal: (open: boolean) => void;
}

const ModalRequestInfo = ({ open, openModal }: IProps) => {
  const needOpen = useSelector(
    (state: RootState) =>
      state.auth.token &&
      state.user.id &&
      (!state.user.cpf ||
        !state.user.cpf_verified_at ||
        state.user.is_underage ||
        state.user.cpf_invalid)
  );

  const next = async () => {
    const sacContact = `${texts.whatsappUrl}${encodeURI(
      texts.whatsappMessage
    )}`;

    window.open(sacContact, "_blank");

    openModal(false);
  };

  useEffect(() => {
    if (needOpen) {
      openModal(true);
    }
  }, [needOpen, openModal]);

  useEffect(() => {}, []);

  return (
    <ModalDialog open={open} setOpen={next}>
      <Box justifyContent="center" alignItems="center" textAlign="center" p={4}>
        <Typography
          fontFamily="Montserrat"
          fontWeight={600}
          fontSize="15px"
          lineHeight="18px"
        >
          <span style={{ color: "#BE1717" }}>atenção!</span>
          <br />
          <br />
          alguns dados cadastrais estão inválidos, por favor, entre contato com
          o suporte para atualizar suas informações.
        </Typography>
        <Box mt={3} display="flex" justifyContent="center">
          <Button variant="contained" onClick={next}>
            entendi!
          </Button>
        </Box>
      </Box>
    </ModalDialog>
  );
};
export default ModalRequestInfo;
