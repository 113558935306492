import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Menu, TermsOfUse } from "components";
import { useAuth, useShoppingCart } from "hooks";
import { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { PersistGate } from "redux-persist/integration/react";
import { Routing } from "routing";
import { menuActions, shoppingCartActions, store } from "state";
import { RootState, persistor } from "state/store";
import theme from "./theme";

const Setup = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const lastUserAccess = useSelector(
    (state: RootState) => state.shoppingCart.lastUserAccess
  );
  const { refresh } = useAuth();

  const closeMenu = () => {
    dispatch(menuActions.setOpen(false));
  };
  const loggedIn = useSelector((state: RootState) => state.auth.token);
  const termsOfUse = useSelector(
    (state: RootState) => state.user.accepted_terms_of_use
  );
  useShoppingCart(true);

  const handlers = useSwipeable({
    onSwipedLeft: closeMenu,
    trackMouse: true,
  });

  useEffect(() => {
    const TIME_ELAPSED = Date.now() - lastUserAccess;
    const TWO_HOURS = 2 * 60 * 60 * 1000;

    if (lastUserAccess && TIME_ELAPSED > TWO_HOURS) {
      console.log("[last-access] limpando carrinho...");
      dispatch(shoppingCartActions.cleanShoppingCart());
    } else {
      dispatch(shoppingCartActions.checkLastUserAccess());
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        fontFamily: "Montserrat",
      }}
      {...handlers}
    >
      {loggedIn && !termsOfUse ? <TermsOfUse /> : <></>}
      <BrowserRouter>
        <CssBaseline />
        <Menu />
        <Routing />
      </BrowserRouter>
    </Box>
  );
};

export default Setup;
