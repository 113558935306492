export const texts = {
  signup: "avançar",
  nameTitle: "seu nome",
  namePlaceholder: "nome",
  numberTitle: "seu telefone",
  numberPlaceholder: "(00) 00000-0000",
  emailTitle: "seu email",
  emailPlaceholder: "email",
  cpfTitle: "seu CPF",
  cpfPlaceholder: "000.000.000-00",
  addressLabel: "endereço do estabelecimento",
  addressPlaceholder: "rua fulano e tal, 88, bairro",
  noAccount: "já tem uma conta?",
  noAccountLink: "faça o login aqui",
};
