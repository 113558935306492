import { ReactNode } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface ScreenTitleProps {
  children: ReactNode;
}

const ScreenTitle = ({ children }: ScreenTitleProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        component="h1"
        sx={{
          mb: "4px",
          fontSize: 16,
          fontWeight: 600,
          fontFamily: "Montserrat",
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default ScreenTitle;
